import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { normalize } from 'normalizr'
import { user } from '../constants/schemas'
import { LoadingStates } from '../constants/sliceTypes/loadingStates'
import {
  EditUserData,
  EditUserToSend,
  RegistrationData,
  UserState,
  ShortLinkData,
  Avatar,
} from '../constants/sliceTypes/userSlice'
import fetchApi from '../functions/fetch'
import { Methods } from '../functions/fetch'
import { getActiveAccountId } from '../selectorFunctions'
import { RootState } from '../store/configureStore'
import { Account } from './accountsSlice'

const userState: UserState = {
  authUserID: '',
  loginState: '',
  registrationState: '',
  editState: '',
  fetchUsersState: '',
  leaveAccountState: '',
  shortInviteLink: '',
  shortInviteLinkState: '',
  byID: {},
}

export const avatarUpload = async (image: File) => {
  let formData = new FormData()
  formData.append('avatar', image)
  return await fetchApi(`avatars`, { method: Methods.Post, data: formData, asFormData: true })
}

enum UserStates {
  Active = 'ACTIVE',
  NotRegistered = 'NON_REGISTERED',
}

interface ApiUser {
  _id: string
  firstName: string
  lastName: string
  email: string
  showChangePassword?: boolean
  avatar: Avatar
  state: UserStates
  accounts: Account[]
}

export interface NormalizedUser extends Omit<ApiUser, 'accounts'> {
  accounts: string[]
}

interface NormalizeUserResult {
  result: string
  entities: {
    users: { [key: string]: NormalizedUser }
    accounts: { [key: string]: Account }
  }
}

export const authLogin = createAsyncThunk('user/login', async () => {
  const data: ApiUser = await fetchApi('authorization', { method: Methods.Post })

  return normalize(data, user) as NormalizeUserResult
})

export const registration = createAsyncThunk(
  'user/registration',
  async (registrationData: RegistrationData) => {
    const { image, ...rest } = registrationData
    let data = {}
    if (image) {
      const url = await avatarUpload(image)
      data = await fetchApi('register', {
        method: Methods.Post,
        data: { ...rest, avatarUrl: url.avatarUrl },
      })
    }
    data = await fetchApi('register', { method: Methods.Post, data: { ...rest } })
    return normalize(data, user).entities.users
  }
)

interface NormalizeUsersResult {
  result: string[]
  entities: { users: { [key: string]: NormalizedUser } }
}

export const fetchUsers = createAsyncThunk('users/fetch', async (accountId: string) => {
  const users = await fetchApi(`accounts/${accountId}/users`)

  return normalize(users, [user]) as NormalizeUsersResult
})

export const leaveAccount = createAsyncThunk(
  'user/leaveAccount',
  async (userId: string, { getState }) => {
    const accountId = getActiveAccountId()(getState() as RootState)

    return await fetchApi(`accounts/${accountId}/leave`, { method: Methods.Put, data: { userId } })
  }
)

export const editUserSetting = createAsyncThunk('user/edit', async (editData: EditUserData) => {
  const { userID } = editData

  let toSend: EditUserToSend = {
    firstName: editData.firstName,
    lastName: editData.lastName,
  }

  let data = {}

  if (editData.deleteAvatar) {
    await fetchApi(`users/${userID}/avatar`, { method: Methods.Delete })
    data = await fetchApi(`users/${userID}`, { method: Methods.Put, data: toSend })
  } else if (editData.image) {
    const url = await avatarUpload(editData.image)
    toSend.avatarUrl = url.avatarUrl
    data = await fetchApi(`users/${userID}`, { method: Methods.Put, data: toSend })
  } else {
    data = await fetchApi(`users/${userID}`, { method: Methods.Put, data: toSend })
  }

  return normalize(data, user).entities.users
})

export const shortInviteUserLink = createAsyncThunk(
  'user/shortInviteLink',
  async (data: ShortLinkData) => {
    const { longDynamicLink, domainUriPrefix } = data

    const toSend = {
      dynamicLinkInfo: {
        domainUriPrefix,
        link: longDynamicLink,
      },
      suffix: {
        option: 'UNGUESSABLE',
      },
    }

    const getShortLink = await fetch(
      `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${process.env.REACT_APP_FIREBASE_API_KEY}`,
      {
        method: 'POST',
        body: JSON.stringify(toSend),
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )

    return await getShortLink.json()
  }
)

const userSlice = createSlice({
  name: 'user',
  initialState: userState,
  reducers: {
    addUsers: (state, action) => {
      state.byID = { ...action.payload.users, ...state.byID }
    },
    clearEditState: (state) => {
      state.editState = LoadingStates.Empty
    },
  },
  extraReducers: (builder) => {
    builder.addCase(authLogin.fulfilled, (state, { payload }) => {
      state.loginState = LoadingStates.Succeeded
      state.authUserID = payload.result
      state.byID = { ...state.byID, ...payload.entities.users }
    })

    builder.addCase(authLogin.pending, (state) => {
      state.loginState = LoadingStates.Pending
    })

    builder.addCase(authLogin.rejected, (state) => {
      state.loginState = LoadingStates.Failed
    })

    builder.addCase(editUserSetting.fulfilled, (state, { payload }) => {
      state.editState = LoadingStates.Succeeded
      state.byID[state.authUserID] = payload ? payload[state.authUserID] : {}
    })

    builder.addCase(editUserSetting.pending, (state) => {
      state.editState = LoadingStates.Pending
    })

    builder.addCase(editUserSetting.rejected, (state) => {
      state.editState = LoadingStates.Failed
    })

    builder.addCase(registration.fulfilled, (state, { payload }) => {
      state.registrationState = LoadingStates.Succeeded
      state.byID[state.authUserID] = payload ? payload[state.authUserID] : {}
    })

    builder.addCase(registration.pending, (state) => {
      state.registrationState = LoadingStates.Pending
    })

    builder.addCase(registration.rejected, (state) => {
      state.registrationState = LoadingStates.Failed
    })

    builder.addCase(fetchUsers.fulfilled, (state, { payload }) => {
      state.byID = { ...payload.entities.users, ...state.byID }
      state.fetchUsersState = LoadingStates.Succeeded
    })

    builder.addCase(fetchUsers.pending, (state) => {
      state.fetchUsersState = LoadingStates.Pending
    })

    builder.addCase(fetchUsers.rejected, (state) => {
      state.fetchUsersState = LoadingStates.Failed
    })

    builder.addCase(leaveAccount.fulfilled, (state, { meta }) => {
      delete state.byID[meta.arg]
      state.leaveAccountState = LoadingStates.Succeeded
    })

    builder.addCase(leaveAccount.pending, (state) => {
      state.leaveAccountState = LoadingStates.Pending
    })

    builder.addCase(leaveAccount.rejected, (state) => {
      state.leaveAccountState = LoadingStates.Failed
    })

    builder.addCase(shortInviteUserLink.fulfilled, (state, { payload }) => {
      state.shortInviteLink = payload.shortLink
      state.leaveAccountState = LoadingStates.Succeeded
    })

    builder.addCase(shortInviteUserLink.pending, (state) => {
      state.leaveAccountState = LoadingStates.Pending
    })

    builder.addCase(shortInviteUserLink.rejected, (state) => {
      state.leaveAccountState = LoadingStates.Failed
    })
  },
})

const { actions, reducer } = userSlice
export const { addUsers, clearEditState } = actions
export default reducer
