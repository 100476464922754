import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { headerData } from '../../../../constants/statistics/headerData'
import { renderHeaderItems } from './renderHeaderItems'

interface Props {
  interval?: string
}

const FixedTop = ({ interval }: Props) => {
  const location = useLocation()
  const history = useHistory()

  return (
    <TopContainer>{renderHeaderItems(headerData, true, location, history, interval)}</TopContainer>
  )
}

export default FixedTop

const TopContainer = styled.div``
