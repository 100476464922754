import { useEffect, MutableRefObject } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { useState } from '../../../../hooks'
import { getActiveAccountId } from '../../../../selectorFunctions'
import BaseStep from './BaseStep'
import CheckboxInput from './CheckboxInput'
import { Values } from './inputHelpers'
import RadioButton from './RadioInput'
import Spinner from './Spinner'
import { Label, NarrowInner } from './styled'

enum Recurring {
  Monthly = 'MONTHLY',
  Yearly = 'YEARLY',
}

interface Props {
  values: Values
  valuesRef: MutableRefObject<Values>
  onContinue: (paymentUrl: string) => void
  onBack: () => void
  onValues: (values: Values) => void
}

const Step3 = (props: Props) => {
  const activeAccountId = useSelector(getActiveAccountId())
  const [ordering, setOrdering] = useState(false)
  const [agree, setAgree, agreeRef] = useState(props.values.agree)

  const [recurring, setRecurring, recurringRef] = useState<Recurring>(
    props.values.recurring as Recurring
  )

  useEffect(
    () => () => props.onValues({ agree: agreeRef.current, recurring: recurringRef.current }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  function placeOrder() {
    setOrdering(true)

    const { agree, ...values } = props.valuesRef.current

    fetch(`${process.env.REACT_APP_API_URL}/accounts/${activeAccountId}/plans`, {
      method: 'POST',
      body: JSON.stringify({ ...values, recurring }),
    })
      .then((response) => {
        if (!response.ok) throw response.statusText

        return response.json()
      })
      .then(({ paymentUrl }) => props.onContinue(paymentUrl))
      .catch((error) => {
        console.error(error)
        alert('Něco se pokazilo :(')
        setOrdering(false)
      })
  }

  return (
    <StyledBaseStep title="NADPIS" onBack={props.onBack} controlsDisabled={ordering}>
      <NarrowInner>
        <Label>Forma předplatného</Label>
        <RadioButton
          disabled={ordering}
          name="subscription"
          onSelect={() => setRecurring(Recurring.Monthly)}
          checked={recurring === Recurring.Monthly}
          recurring="Měsíční"
          price="XYZ Kč/měsíc"
        />
        <RadioButton
          disabled={ordering}
          name="subscription"
          onSelect={() => setRecurring(Recurring.Yearly)}
          checked={recurring === Recurring.Yearly}
          recurring="Roční"
          price="ABCD Kč/rok"
        />
        <StyledCheckboxInput
          disabled={ordering}
          label={
            <Label as="span">
              Souhlasím s{' '}
              <Link href="https://foosapp.eu/business-terms">Obchodními podmínkami</Link> a se{' '}
              <Link href="https://foosapp.eu/gdpr">zpracováním osobních údajů</Link>.
            </Label>
          }
          value={agree}
          onChange={setAgree}
        />
        <OrderButton
          onClick={placeOrder}
          disabled={!agree || !recurring || ordering}
          ordering={ordering}
        >
          {ordering ? <Spinner color="#fff" size="1.55555555556em" /> : 'Objednat a zaplatit'}
        </OrderButton>
      </NarrowInner>
    </StyledBaseStep>
  )
}

export default Step3

const StyledBaseStep = styled(BaseStep)``

interface OrderButtonProps {
  ordering: boolean
}

const OrderButton = styled.button<OrderButtonProps>`
  margin: 1.77777777778em auto;
  height: 2.66666666667em;
  width: 9.33333333333em;
  color: #fff;
  font-family: 'Roboto Condensed';
  font-size: 1.125em;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1.22222222222em;
  display: grid;
  place-items: center;
  border-radius: 0.33333333333em;
  background: #52a00b;
  padding: 0;
  border: 0;
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  :disabled {
    cursor: default;
    background: ${({ ordering }) => (ordering ? '#52a00b' : '#bebebe')};
  }
`

const StyledCheckboxInput = styled(CheckboxInput)`
  margin-top: 2.125em;
`

const Link = styled.a.attrs({ target: '_blank', onClick: (event) => event.stopPropagation() })`
  color: #979797;
`
