import styled, { css } from 'styled-components'

import RadioIcon from './RadioIcon'

interface Props {
  recurring: string
  price: string
  name: string
  checked: boolean
  disabled?: boolean
  onSelect: () => void
}

const RadioButton = (props: Props) => (
  <Label disabled={props.disabled}>
    <Input
      name={props.name}
      checked={props.checked}
      type="checkbox"
      onChange={props.onSelect}
      disabled={props.disabled}
    />
    <RadioIcon />
    <Recurring>{props.recurring}</Recurring>
    <Price>{props.price}</Price>
  </Label>
)

export default RadioButton

const Input = styled.input`
  position: absolute;
  top: -10em;

  :checked + svg circle {
    opacity: 1;
  }

  :checked + svg path {
    fill: #52a00b;
  }

  :hover + svg,
  :focus-visible + svg {
    path {
      fill: #787878;
    }
  }

  :disabled + svg path {
    fill: #bebebe;
  }
`

interface LabelProps {
  disabled?: boolean
}

const Label = styled.label<LabelProps>`
  position: relative;
  overflow: hidden;
  cursor: pointer;
  margin: 0.5em 0;
  display: grid;
  border: 0.0625em solid #bebebe;
  border-radius: 0.375em;
  padding: 0.75em 0 1em 0.6875em;
  color: #2a2a2a;
  font-family: 'Roboto Condensed';
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto;
  transition: all 0.2s ease-in-out;
  grid-template-areas:
    'Icon Recurrence'
    'Icon Price';

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`

const Recurring = styled.p`
  grid-area: Recurrence;
  letter-spacing: 0;
  line-height: 1.1875em;
  margin: 0;
`

const Price = styled.p`
  grid-area: Price;
  font-size: 1.25em;
  font-weight: bold;
  letter-spacing: 0.014em;
  line-height: 1.2em;
  margin: 0.5em 0 0;
`
