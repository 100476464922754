import styled from 'styled-components'

interface TextProps {
  color: string
}

export default styled.span<TextProps>`
  color: ${({ color }) => color};
  font-family: Roboto Condensed;
  font-weight: bold;
  line-height: 1.2em;
  text-decoration: underline;
`
