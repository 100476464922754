import { useEffect, useState } from 'react'
import { useToRef } from '../../../../hooks'

interface DataEntry {
  value?: string
  isError?: boolean
}

interface Data {
  [key: string]: DataEntry
}

export interface Values {
  [key: string]: any
}

export function useTextInput(defaultValue: string, validation?: RegExp) {
  const [data, setData] = useState<DataEntry>({ value: defaultValue })

  function onChange(value: string) {
    setData({
      value,
      isError:
        value === '' || (!!validation && typeof value === 'string' && !validation.test(value)),
    })
  }

  return { ...data, onChange }
}

export function isValid(data: Data) {
  return Object.values(data).every(({ value, isError }) => value && !isError)
}

export function useValuesOnLeave(data: Data, onValues: (values: Values) => void) {
  const dataRef = useToRef(data)

  useEffect(
    () => () => {
      const values = Object.entries(dataRef.current!).reduce((values: Values, [key, { value }]) => {
        values[key] = value!

        return values
      }, {})

      onValues(values)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
}
