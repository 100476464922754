import { useRef, useState } from 'react'

import { Values } from './inputHelpers'

import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'

const OrderForm = () => {
  const [stepIndex, setStepIndex] = useState(0)
  const valuesRef = useRef<Values>({})
  const paymentUrlRef = useRef<string>('')

  function onValues(newValues: Values) {
    valuesRef.current = { ...valuesRef.current, ...newValues }
  }

  function onBack() {
    setStepIndex(stepIndex - 1)
  }

  function onContinue() {
    setStepIndex(stepIndex + 1)
  }

  function onStep3Continue(paymentUrl: string) {
    paymentUrlRef.current = paymentUrl
    onContinue()
  }

  const commonProps = { values: valuesRef.current, valuesRef, onValues }

  switch (stepIndex) {
    case 0:
      return <Step1 {...commonProps} onContinue={onContinue} />

    case 1:
      return <Step2 {...commonProps} onBack={onBack} onContinue={onContinue} />

    case 2:
      return (
        <Step3
          {...commonProps}
          onBack={onBack}
          onContinue={onStep3Continue}
          valuesRef={valuesRef}
        />
      )

    case 3:
      return <Step4 paymentUrlRef={paymentUrlRef} />
  }

  return null
}

export default OrderForm
