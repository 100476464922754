import { Dispatch, SetStateAction, useState, createContext, ReactNode } from 'react'

interface MenuContextType {
  isMenuOpen: boolean
  setIsMenuOpen: Dispatch<SetStateAction<boolean>>
}

export const MenuContext = createContext<MenuContextType>({} as MenuContextType)

interface Props {
  children: ReactNode
}

const MenuContextProvider = ({ children }: Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(true)

  return (
    <MenuContext.Provider value={{ isMenuOpen, setIsMenuOpen }}>{children}</MenuContext.Provider>
  )
}

export default MenuContextProvider
