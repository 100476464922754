import { FormType } from '../constants/forms/types'
import { StateType } from '../Input/InputStateType'

function isFormValid(inputValue: StateType, formData: FormType[]) {
  return formData.every((item: FormType) => {
    if (!item.required) {
      return true
    } else if (!inputValue[item.name]) {
      return false
    } else if (inputValue[item.name].isInvalid) {
      return false
    }
    return true
  })
}
export default isFormValid
