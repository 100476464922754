import { SetStateAction } from 'react'
import styled from 'styled-components'

import SelectInput from '../../../Input'
import { StatisticsSelects } from '../../../constants/forms/data'
import { FormType } from '../../../constants/forms/types'
import handleChange from '../../../functions/handleChange'
import { HandleChangeEvent } from '../../../Input/HandleChangeEvent'
import { StateType } from '../../../Input/InputStateType'
import { SelectOptions } from '../../../Input/Select'

interface Props {
  inputValue: StateType
  setInputValue: SetStateAction<any>
  options: SelectOptions[]
}

const FilterButtons = ({ inputValue, setInputValue, options }: Props) => {
  return (
    <Container>
      {StatisticsSelects.map((formInput: FormType) => (
        <SelectInput
          key={formInput.name}
          formInput={formInput}
          options={options}
          handleChange={(formInput, e: HandleChangeEvent) =>
            handleChange(formInput, e, inputValue, setInputValue)
          }
          value={(inputValue[formInput.name] && inputValue[formInput.name].value) || ''}
          isInvalid={inputValue[formInput.name] && inputValue[formInput.name].isInvalid}
        />
      ))}
    </Container>
  )
}

export default FilterButtons

const Container = styled.div`
  width: 24.5em;
  display: flex;
  position: relative;
  top: 0.5em;
  justify-content: space-between;

  @media (max-width: 768px) {
    height: 3.5em;
    bottom: 0.2em;
  }
`
