import { Dispatch, SetStateAction } from 'react'
import styled from 'styled-components'

import UserMenu from '../../../layout/UserMenu/'
import { PathKeys } from '../../../layout/UserMenu/Icon'
import Close from '../../../styled/Close'
import Title from '../../../styled/Title'
import Main from './Main'

interface Props {
  setGenerateLinkActive: Dispatch<SetStateAction<boolean>>
  generateLinkActive: boolean
  setVisible: Dispatch<SetStateAction<boolean>>
  visible: boolean
  gameTypeId: string
  interval: string
}

const Mobile = ({
  setGenerateLinkActive,
  generateLinkActive,
  setVisible,
  visible,
  gameTypeId,
  interval,
}: Props) => {
  return visible ? (
    <Container>
      <UserMenu activePath={PathKeys.Statistics} />
      <Line />
      <Content>
        <Top>
          <Title>NASTAVENÍ SDÍLENÍ</Title>
          <Close top={-0.1} onClick={() => setVisible(false)} imageUrl="./img/close.svg" />
        </Top>
        <Main
          gameTypeId={gameTypeId}
          interval={interval}
          mobile={true}
          setGenerateLinkActive={setGenerateLinkActive}
          generateLinkActive={generateLinkActive}
        />
      </Content>
    </Container>
  ) : (
    <></>
  )
}

export default Mobile

const Line = styled.hr`
  width: 90%;
  border-top: 1px solid #bebebe;
`

const Container = styled.div`
  background-color: white;
  width: 100%;
  height: 100%;
  z-index: 999;
  top: 0;
  left: 0;
  position: fixed;
`

const Content = styled.div`
  max-width: 100%;
`

const Top = styled.section`
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 1.5em;
`
