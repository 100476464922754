import { ReactNode } from 'react'
import styled from 'styled-components'

import { inset } from '../../../../utils/styles'
import Close from './Close'
import { Title } from './styled'

interface Props {
  children: ReactNode
  title: ReactNode
  controlsDisabled?: boolean
  className?: string
  onBack?: () => void
}

const BaseStep = (props: Props) => (
  <Container className={props.className}>
    <Content>
      <Close disabled={props.controlsDisabled} />
      <Title>{props.title}</Title>
      {props.onBack && (
        <Back onClick={props.onBack} disabled={props.controlsDisabled}>
          &lt; Zpět
        </Back>
      )}
      {props.children}
    </Content>
  </Container>
)

export default BaseStep

const Container = styled.div`
  position: fixed;
  ${inset(0)}
  background: rgba(42, 42, 42, 0.75);
  display: grid;
  place-items: center;
  z-index: 999;
`

export const Content = styled.div`
  position: relative;
  border-radius: 0.375em;
  background-color: #ffffff;
  box-shadow: 0 0 0.5em 0 rgba(42, 42, 42, 0.6);
  font-family: 'Roboto Condensed';

  @media (max-width: 31.25em) {
    width: 100%;
    height: 100%;
    border-radius: 0;
    overflow: auto;
  }
`

const Back = styled.button`
  margin: 0 0 2em 2.66666666667em;
  padding: 0;
  border: none;
  background: transparent;
  color: #ff8c1a;
  font-family: 'Roboto Condensed';
  font-size: 0.75em;
  font-weight: bold;
  letter-spacing: 0;
  cursor: pointer;
  transition: color 0.2s ease-in-out;

  :hover {
    color: #c86809;
  }

  :disabled {
    cursor: default;
    color: #bebebe;
  }
`
