import { useEffect, useState } from 'react'
import Main from '../Default/Main'
import { StateType } from '../../../../Input/InputStateType'
import { useDispatch, useSelector } from 'react-redux'
import reduceInputData from '../../../../functions/reduceInputData'
import isFormValid from '../../../../functions/IsFormValid'
import { userData } from '../../../../constants/forms/data'
import { getLoggedUser, getRegistrationUser, getUserState } from '../../../../selectorFunctions'
import { registration } from '../../../../slices/userSlice'
import { LoadingStates } from '../../../../constants/sliceTypes/loadingStates'

const PopUp = () => {
  const [image, setImage] = useState<any>()
  const [userInfo, setUserInfo] = useState({})
  const dispatch = useDispatch()
  const [active, setActive] = useState(false)
  const registrationUser = useSelector(getRegistrationUser())
  const loggedUser = useSelector(getLoggedUser())
  const { registrationState } = useSelector(getUserState())

  function getUserInfo(data: StateType, image: File | undefined) {
    setUserInfo(data)
    setImage(image)
  }

  let prefilledData = {}
  if (registrationUser && registrationUser.firstName && registrationUser.lastName) {
    let isInvalid = false

    prefilledData = {
      name: { value: registrationUser.firstName, isInvalid },
      surname: { value: registrationUser.lastName, isInvalid },
    }
  }

  useEffect(() => {
    if (loggedUser && loggedUser.state && loggedUser.state !== 'ACTIVE' && !active) {
      setActive(true)
    } else if (active) {
      setActive(false)
    }
  }, [loggedUser]) // eslint-disable-line

  function handleSubmit() {
    if (isFormValid(userInfo, userData)) {
      const reduced = reduceInputData(userInfo)

      let dataToSent = {
        firstName: reduced.name,
        lastName: reduced.surname,
      }

      if (image) {
        dispatch(registration({ ...dataToSent, image }))
      } else {
        dispatch(registration(dataToSent))
      }
    }
  }

  return (
    <Main
      sendButtonText="pokračovat"
      loading={registrationState === LoadingStates.Pending}
      titleText="OVĚŘENÍ"
      active={active}
      withDelete={false}
      avatar={
        registrationUser && registrationUser.avatar
          ? registrationUser.avatar.L
          : './img/no-avatar.svg'
      }
      getUserInfo={getUserInfo}
      handleSubmit={handleSubmit}
      prefilledData={prefilledData}
    />
  )
}

export default PopUp
