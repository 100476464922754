import { useEffect, MutableRefObject } from 'react'
import styled from 'styled-components'

import BaseStep, { Content } from './BaseStep'
import { Button } from './Close'
import Spinner from './Spinner'
import { Continue, Title } from './styled'

interface Props {
  paymentUrlRef: MutableRefObject<string>
}

const Step4 = (props: Props) => {
  function redirect() {
    window.location.href = props.paymentUrlRef.current
  }

  useEffect(() => {
    const timeout = setTimeout(redirect, 5000)

    return () => clearTimeout(timeout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <StyledBaseStep
      title={
        <>
          Za 5 sekund budete přesměrováni
          <br />
          na platební bránu
        </>
      }
    >
      <Spinner color="#52A00B" size="2.625em" />
      <Continue onClick={redirect} />
    </StyledBaseStep>
  )
}

export default Step4

const StyledBaseStep = styled(BaseStep)`
  ${Content} {
    width: 28.9375em;
    height: 30.625em;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 31.25em) {
      width: 100%;
      height: 100%;
    }
  }

  ${Button} {
    display: none;
  }

  ${Title} {
    text-align: center;
    margin: 4.8em auto 0.4em;
  }

  ${Continue} {
    width: auto;
    margin-top: 9.33333333333em;

    :after {
      content: 'Pokračovat na platební bránu';
    }
  }
`
