import styled from 'styled-components'

export default styled.div`
  width: 100%;
  background-image: url('./img/background.png');
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
  min-height: 100vh;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`
