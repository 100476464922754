import styled from 'styled-components'
import { useAuth0 } from '@auth0/auth0-react'

import MenuLinkContainer from './MenuLinkContainer'
import Icon, { PathKeys } from './Icon'

const Logout = () => {
  const { logout } = useAuth0()

  function handleLogout() {
    logout({ returnTo: `${process.env.REACT_APP_APP_URL}` })
    localStorage.clear()
  }

  return (
    <LogoutContainer onClick={handleLogout}>
      <Icon path={PathKeys.Logout} />
      <span>Odhlásit se</span>
    </LogoutContainer>
  )
}

export default Logout

const LogoutContainer = styled(MenuLinkContainer)`
  margin-bottom: 1em;
`
