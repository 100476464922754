import styled, { css } from 'styled-components'
import { HeaderData, SCROLLABLE, NO_SCROLLABLE } from '../../../../constants/statistics/headerData'
import { SortingValues, SortingValuesText } from '../../../../constants/statistics/sorting'
import handleActiveSorting from '../../../../functions/handleActiveSorting'
import handleSort from '../../../../functions/handleSort'
import { Location, History } from 'history'
import headerSectionStyles from './headerSectionStyles'

export function renderHeaderItems(
  headerItems: HeaderData[],
  forFixed: boolean,
  location: Location,
  history: History,
  interval?: string
) {
  return headerItems.map((item, index) => {
    if (item.textKey === SortingValues.ELO && interval) {
      return null
    }

    if (
      (item.textKey === SCROLLABLE && forFixed) ||
      (item.textKey === NO_SCROLLABLE && !forFixed)
    ) {
      return null
    }

    if (item.textKey === SCROLLABLE) {
      return (
        <Scrollable key={index}>
          {
            //@ts-ignore
            renderHeaderItems(item.items, forFixed, location, history, interval)
          }
        </Scrollable>
      )
    } else if (item.textKey === NO_SCROLLABLE) {
      return (
        <NoScrollable key={index}>
          {
            //@ts-ignore
            renderHeaderItems(item.items, forFixed, location, history, interval)
          }
        </NoScrollable>
      )
    } else {
      return (
        <HeaderItem
          name={item.textKey}
          disabledSorting={item.disabledSorting}
          onClick={() =>
            item.disabledSorting ? null : handleSort(item.textKey, location, history, interval)
          }
          widthEm={item.width ? item.width : 7.7}
          key={index}
        >
          <HeaderItemContainer>
            <>{handleActiveSorting(item.textKey, location, item.disabledSorting)}</>
            <HeaderItemText>
              {item.textKey === '#' ? '#' : SortingValuesText[item.textKey]}
            </HeaderItemText>
          </HeaderItemContainer>
        </HeaderItem>
      )
    }
  })
}

interface HeaderItemProps {
  widthEm: number
  disabledSorting?: boolean
  name: string
}

const NoScrollable = styled.tr`
  ${headerSectionStyles}
  width: 19em;
  display: flex;
  align-items: center;
  padding-left: 1.5em;

  @media (max-width: 768px) {
    width: 14.1em;
    padding-left: 0;
  }
`

const Scrollable = styled.tr`
  ${headerSectionStyles}
  width: 100%;
  height: 3.56em;
  text-align: left;
`

const HeaderItem = styled.td<HeaderItemProps>`
  min-width: ${({ widthEm: width }) => width}em;
  cursor: ${({ disabledSorting }) => (disabledSorting ? 'default' : 'pointer')};
  text-align: center;

  ${({ name }) =>
    name === 'ELO' &&
    css`
      padding-left: 12em;

      @media (max-width: 768px) {
        padding-left: 10em;
      }
    `}

  ${({ name }) =>
    name === 'ACTION' &&
    css`
      padding-right: 4em;
    `}
`

const HeaderItemContainer = styled.div`
  display: flex;
  justify-content: center;
`

const HeaderItemText = styled.div`
  color: #2a2a2a;
  font-family: Roboto Condensed;
  font-size: 1.125em;
  font-weight: bold;
  line-height: 1.3em;
  white-space: nowrap;
`
