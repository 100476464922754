import { css } from 'styled-components'

export const itemStyles = css`
  color: #2a2a2a;
  font-family: Roboto Condensed;
  font-size: 1em;
  font-weight: bold;
  line-height: 1.1875em;
  padding-top: 1.4em;
  padding-bottom: 1.4em;
`
