import { Link } from 'react-router-dom'
import styled from 'styled-components'

interface Props {
  icon: string
  text: string
  to: string
}

const Card = (props: Props) => (
  <Container to={props.to}>
    <Icon src={`/img/${props.icon}.svg`} />
    <Text>{props.text}</Text>
  </Container>
)

export default Card

const Container = styled(Link)`
  text-decoration: none;
  color: #2a2a2a;
  font-size: 1em;
  width: 11.1875em;
  background-color: #fefefe;
  box-shadow: 0 0.0625em 0.625em -0.0625em rgba(0, 0, 0, 0.08);
  border: 0.0625em solid rgba(0, 0, 0, 0.1);
  border-radius: 0.375em;
  padding: 1.5em 0;
  transition: box-shadow 0.2s ease-in-out;
  text-align: center;

  :hover {
    box-shadow: 0 0.125em 1.125em -0.0625em rgba(0, 0, 0, 0.2);
  }
`

const Icon = styled.img`
  width: 4.5em;
  height: 4.5em;
  margin-bottom: 1em;
`

const Text = styled.p`
  font-family: 'Roboto Condensed';
  font-weight: bold;
  line-height: 1.1875;
  letter-spacing: 0.014375em;
  padding: 0.5625em 0;
  margin: 0;
`
