export enum TYPES {
  TEXT = 'TEXT',
  PASSWORD = 'PASSWORD',
  SELECT = 'SELECT',
}

interface Input {
  text: string
  required: boolean
  name: string
  height?: number
  mobileWidth?: number
  width?: number
  placeholder: string
}

interface Select {
  type: TYPES.SELECT
  name: string
  height?: number
  width?: number
  mobileWidth?: number
  required: boolean
}

interface TextInput extends Input {
  type: TYPES.TEXT
}

interface PasswordInput extends Input {
  type: TYPES.PASSWORD
}

export type FormType = TextInput | PasswordInput | Select
