import queryString from 'query-string'

export default function createRequestUri(obj: any) {
  const keys = Object.keys(obj)

  keys.forEach((key) => {
    if (!obj[key]) {
      delete obj[key]
    }
  })

  return queryString.stringify(obj)
}
