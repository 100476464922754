import { useContext, FC } from 'react'

import MainContainer from '../styled/MainContainer'
import VerticalContainer from '../styled/VerticalContainer'
import UserMenu from './UserMenu'
import { PathKeys } from './UserMenu/Icon'
import { MenuContext } from './UserMenu/MenuContextProvider'

interface Props {
  activePath: PathKeys
  Component: FC
}

const UserMenuLayout = ({ activePath, Component }: Props) => {
  const { isMenuOpen } = useContext(MenuContext)

  return (
    <MainContainer>
      <UserMenu activePath={activePath} />
      <VerticalContainer isMenuOpen={isMenuOpen}>
        <Component />
      </VerticalContainer>
    </MainContainer>
  )
}

export default UserMenuLayout
