import { Dispatch, useEffect, useRef, ReactNode, SetStateAction } from 'react'
import styled from 'styled-components'
import Div100vh from 'react-div-100vh'

interface Props {
  children: ReactNode
  align?: string
  setActive?: Dispatch<SetStateAction<boolean>>
}

const FullPageOverlay = ({ children, align, setActive }: Props) => {
  const ref = useRef(null)

  useEffect(() => {
    if (setActive) {
      window.addEventListener('click', (e) => {
        if (ref.current && e.target === ref.current) {
          setActive(false)
        }
      })
    }
  }, []) //eslint-disable-line

  return (
    <>
      <Overlay align={align} ref={ref}>
        {children}
      </Overlay>
    </>
  )
}

export default FullPageOverlay

interface FullPageOverlayProps {
  align?: string
}

const Overlay = styled(Div100vh)<FullPageOverlayProps>`
  background-color: rgba(42, 42, 42, 0.7);
  width: 100%;
  height: 100%;
  z-index: 900;
  top: 0;
  left: 0;
  position: fixed;
  display: flex;
  justify-content: ${({ align }) => (align ? align : 'center')};
  align-items: center;
`
