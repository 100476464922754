import styled from 'styled-components'
import MainTitle from '../../../styled/MainTitle'

interface Props {
  text: string
}

const EmptyStatisticsState = ({ text }: Props) => (
  <Container>
    <SmallTitle>{text}</SmallTitle>
  </Container>
)

export default EmptyStatisticsState

const SmallTitle = styled(MainTitle)`
  font-size: 1.3em;

  @media (max-width: 768px) {
    line-height: 0.3em;
    font-size: 1em;
  }
`

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4em;
  background-color: white;
`
