import { useEffect, FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import useLocalStorage from 'react-use-localstorage'

import { PathKeys } from '../components/layout/UserMenu/Icon'
import UserMenuLayout from '../components/layout/UserMenuLayout'
import { LoadingStates } from '../constants/sliceTypes/loadingStates'
import { getLoggedUser, getUserState } from '../selectorFunctions'
import { authLogin } from '../slices/userSlice'

interface Props {
  Component: FC
  path: string
  activePath: PathKeys
}

const UserMenuRoute = ({ Component, path, activePath, ...rest }: Props) => {
  const loggedUserData = useSelector(getLoggedUser())
  const userState = useSelector(getUserState())
  const [token] = useLocalStorage('token')
  const dispatch = useDispatch()

  useEffect(() => {
    if (!loggedUserData && token) {
      dispatch(authLogin())
    }
  }, [loggedUserData]) //eslint-disable-line

  const renderRouteComponent = () => {
    if (
      userState.loginState === LoadingStates.Succeeded ||
      userState.registrationState === LoadingStates.Empty
    ) {
      return <UserMenuLayout Component={Component} activePath={activePath} />
    }
    return <Redirect to="/" />
  }

  return (
    <Route
      {...rest}
      path={path}
      exact
      render={() => {
        if (loggedUserData) {
          return renderRouteComponent()
        } else if (!token && userState.loginState !== LoadingStates.Pending) {
          return <Redirect to="/" />
        }
      }}
    />
  )
}

export default UserMenuRoute
