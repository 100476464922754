import CircleLoader from 'react-spinners/FadeLoader'
import FullPageOverlay from '../styled/FullPageOverlay'

const Loader = () => (
  <FullPageOverlay>
    <CircleLoader color="#FF8C1A" />
  </FullPageOverlay>
)

export default Loader
