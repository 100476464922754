import styled from 'styled-components'

const ShareButton = () => {
  return <Button imageUrl={'./img/share-icon.svg'} />
}

export default ShareButton

interface ShareButtonProps {
  imageUrl: string
}

const Button = styled.button<ShareButtonProps>`
  background-image: url('${({ imageUrl }) => imageUrl}');
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  width: 2.8rem;
  height: 2.8rem;
  background-color: #ff8c1a;
  border-radius: 0.5em;
  outline: none;
  cursor: pointer;
  position: relative;
  top: 0.5rem;
  left: 1.5rem;
  overflow: visible;

  &:hover {
    background-color: #c86809;
  }

  @media (max-width: 768px) {
    top: -3.4rem;
    left: 16rem;
  }
`
