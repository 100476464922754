import { SortingOrders, SortingValues } from '../constants/statistics/sorting'
import queryString from 'query-string'
import { Location } from 'history'
import { History } from 'history'

export default function handleSort(
  itemText: string,
  location: Location,
  history: History,
  interval?: string
) {
  //@ts-ignore
  const sortingValue = SortingValues[itemText]
  const queryStringObj = queryString.parse(location.search)

  if (sortingValue !== queryStringObj['sortBy']) {
    delete queryStringObj['order']
  }

  queryStringObj['sortBy'] = sortingValue
  if (!queryStringObj['order']) {
    queryStringObj['order'] = SortingOrders.DESC
  } else if (queryStringObj['order'] === SortingOrders.DESC) {
    queryStringObj['order'] = SortingOrders.ASC
  } else {
    const defaultSortBy = interval ? 'GAMES_COUNT' : 'ELO'
    queryStringObj['order'] = 'DESC'
    queryStringObj['sortBy'] = defaultSortBy
  }

  history.push(`?${queryString.stringify(queryStringObj)}`)
}
