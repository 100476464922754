import { css } from 'styled-components'

function insetToPosition(inset: string | number) {
  if (typeof inset === 'number') inset = inset.toString()

  let [top, right, bottom, left] = inset.split(' ')

  if (right === undefined) left = bottom = right = top
  else if (bottom === undefined) {
    left = right
    bottom = top
  } else if (left === undefined) left = right

  return { top, right, left, bottom }
}

export function inset(inset: string | number) {
  return css(insetToPosition(inset))
}
