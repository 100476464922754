import styled, { css } from 'styled-components'

import { Label } from './styled'

interface Props {
  label: string
  value?: string
  helper?: string
  isError?: boolean
  isEnabled?: boolean
  onChange: (value: string) => void
}

const TextInput = (props: Props) => (
  <Container isEnabled={props.isEnabled} isError={props.isError}>
    <Label>{props.label}</Label>
    <Input
      value={props.value || ''}
      onChange={(event) => props.onChange(event.target.value)}
      disabled={props.isEnabled === false}
    />
    <Helper isVisible={!!props.helper}>{props.helper}</Helper>
  </Container>
)

export default TextInput

const Input = styled.input`
  font-size: 1em;
  margin-top: 0.5em;
  color: #2a2a2a;
  line-height: 1.0625;
  padding: 0.75em;
  border: 0.0625em solid #bebebe;
  border-radius: 0.375em;
  color: #2a2a2a;
  caret-color: #52a00b;
  outline: none;
  transition: border-color 0.2s ease-in-out;

  :focus {
    border-color: #2a2a2a;
  }

  :disabled {
    background-color: transparent;
  }
`

interface HelperProps {
  isVisible: boolean
}

const Helper = styled.p<HelperProps>`
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  font-size: 0.625em;
  line-height: 1.1;
  letter-spacing: 0.014em;
  font-weight: bold;
  margin: 0.6em 0 0 0.8em;
`

interface ContainerProps {
  isEnabled?: boolean
  isError?: boolean
}

const Container = styled.div<ContainerProps>`
  letter-spacing: 0;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.6875em;
  transition: opacity 0.2s ease-in-out;

  ${({ isEnabled }) =>
    isEnabled === false &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}

  ${({ isError }) =>
    isError &&
    css`
      ${Label}, ${Helper} {
        color: #ff311a;
      }

      ${Input} {
        border-color: #ff311a;
      }
    `}
`
