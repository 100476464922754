import { Dispatch, SetStateAction, ReactNode } from 'react'
import styled from 'styled-components'
import Close from '../styled/Close'
import FullPageOverlay from '../styled/FullPageOverlay'
import Title from '../styled/Title'

interface Size {
  height: string
  width: string
}

interface Props {
  visible: boolean
  setVisible: Dispatch<SetStateAction<boolean>>
  children: ReactNode
  titleText: string
  size: Size
  setStateAction?: Dispatch<SetStateAction<boolean>>
}

const Main = ({ visible, setVisible, titleText, children, size, setStateAction }: Props) => {
  function handleClose() {
    setVisible(false)
    if (setStateAction) {
      setStateAction(false)
    }
  }

  return visible ? (
    <>
      <FullPageOverlay>
        <Container size={size}>
          <CloseContainer>
            <Close onClick={handleClose} imageUrl="/img/close.svg"></Close>
          </CloseContainer>
          <TitleContainer>
            <Title>{titleText}</Title>
          </TitleContainer>
          {children}
        </Container>
      </FullPageOverlay>
    </>
  ) : (
    <></>
  )
}

export default Main

interface ContainerProps {
  size: Size
}

const Container = styled.div<ContainerProps>`
  width: ${({ size }) => size.width};
  height: ${({ size }) => size.height};
  background-color: white;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 8px 0 rgba(42, 42, 42, 0.6);
  border-radius: 0.3em;
`

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding-left: 2em;
`

const CloseContainer = styled.div`
  width: 100%;
  height: 2em;
  display: flex;
  justify-content: flex-end;
`
