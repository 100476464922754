import { SetStateAction } from 'react'
import { Dispatch } from 'react'
import styled from 'styled-components'

interface Props {
  text: string
  marginRight?: number
  checked: boolean
  setChecked: Dispatch<SetStateAction<boolean>>
}

const Checkbox = ({ text, marginRight, checked, setChecked }: Props) => (
  <Container>
    <CheckboxContainer>
      <StyledCheckbox type="checkbox" checked={checked} onChange={() => setChecked(!checked)} />
      <Label htmlFor="image"></Label>
    </CheckboxContainer>
    <CheckboxText marginRight={marginRight}>{text}</CheckboxText>
  </Container>
)

export default Checkbox

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const CheckboxContainer = styled(Container)``

interface CheckboxTextProps {
  marginRight?: number
}

const CheckboxText = styled.span<CheckboxTextProps>`
  color: #2a2a2a;
  font-family: Roboto Condensed;
  font-size: 1em;
  letter-spacing: 0.005em;
  margin-left: 1.5em;
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : 0)}em;
`

const StyledCheckbox = styled.input`
  display: flex;
  opacity: 0;
  position: relative;
  margin-left: -1.5em;
  left: 1.5em;
  cursor: pointer;
  height: 1.25em;
  width: 1.25em;

  + label {
    background-image: url('/img/checkbox-unchecked.svg');
    background-size: 100%;
    height: 1.25em;
    width: 1.25em;
    display: inline-block;
    padding: 0;
  }

  &:checked + label {
    background: url('/img/checkbox-checked.svg') no-repeat;
  }

  &:hover {
    + label {
      background-image: url('/img/checkbox-unchecked-hover.svg');
    }

    &:checked + label {
      background: url('/img/checkbox-checked-hover.svg') no-repeat;
    }
  }
`

const Label = styled.label``
