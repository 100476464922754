import { FormType } from '../constants/forms/types'
import { HandleChangeEvent } from '../Input/HandleChangeEvent'
import { StateType } from '../Input/InputStateType'
import { SetStateAction } from 'react'

function handleChange(
  item: FormType,
  event: HandleChangeEvent,
  inputValue: StateType,
  setInputValue: SetStateAction<any>
) {
  const inputData = {
    ...inputValue,
    ...{
      [item.name]: {
        value: event.target.value,
        isInvalid: item.required && event.target.value === '',
      },
    },
  }
  setInputValue(inputData)
}
export default handleChange
