import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { StatisticsTypeUserString } from '../../../../constants/sliceTypes/statisticsSlice'
import { getUserById } from '../../../../selectorFunctions'
import User from '../../../layout/User'
import { containerStyles } from './containerStyles'
import { itemStyles } from './itemStyles'

interface Props {
  interval?: string
  index: number
  item: StatisticsTypeUserString
}

const FixedItem = ({ interval, index, item }: Props) => {
  const userInfo = useSelector(getUserById(item.user))

  return (
    <>
      <Fixed interval={interval} bgColor={index % 2 === 0 ? 'white' : '#F8F8F8'}>
        <Index>{index + 1}</Index>
        <User user={userInfo} />
      </Fixed>
    </>
  )
}

export default FixedItem

const Fixed = styled.div`
  ${containerStyles}
  padding-left: 2.8em;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    padding-left: 1.3em;
    width: 12.8em;
  }
`

const Index = styled.div`
  ${itemStyles}
  width: 3em;
`
