import styled, { keyframes } from 'styled-components'

interface Props {
  color: string
  size: string
}

const Spinner = (props: Props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 66 66"
    style={{ width: props.size, height: props.size }}
  >
    <circle
      stroke={props.color}
      fill="none"
      strokeWidth="6"
      strokeLinecap="round"
      cx="33"
      cy="33"
      r="30"
    />
  </Svg>
)

export default Spinner

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(270deg);
  }
`

const dash = keyframes`
  0% {
    stroke-dashoffset: 187;
  }

  50% {
    stroke-dashoffset: 46.75;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }

  100% {
    stroke-dashoffset: 187;
    -webkit-transform: rotate(450deg);
    transform: rotate(450deg);
  }
`

const Svg = styled.svg`
  animation: ${rotate} 1.4s linear infinite;

  circle {
    stroke-dasharray: 187;
    stroke-dashoffset: 0;
    transform-origin: center;
    animation: ${dash} 1.4s ease-in-out infinite;
  }
`
