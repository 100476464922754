import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { GameType, GameTypesState } from '../constants/sliceTypes/gameTypesSlice'
import { LoadingStates } from '../constants/sliceTypes/loadingStates'
import fetch from '../functions/fetch'

const gameTypesState: GameTypesState = {
  state: '',
  data: [],
}

export const fetchGameTypes = createAsyncThunk<GameType[], string>('gameTypes/fetch', (accountId) =>
  fetch(`accounts/${accountId}/game-types`)
)

export const fetchSharingGameTypes = createAsyncThunk<GameType[], string>(
  'gameTypes/sharingFetch',
  async (urlKey: string) => {
    return await fetch(`embeds/game-types?urlKey=${urlKey}`, { withToken: false })
  }
)

const gameTypesSlice = createSlice({
  name: 'gameTypes',
  initialState: gameTypesState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchGameTypes.fulfilled, (state, { payload }) => {
      state.state = LoadingStates.Succeeded
      state.data = payload
    })

    builder.addCase(fetchGameTypes.pending, (state) => {
      state.state = LoadingStates.Pending
    })

    builder.addCase(fetchGameTypes.rejected, (state) => {
      state.state = LoadingStates.Failed
    })

    builder.addCase(fetchSharingGameTypes.fulfilled, (state, { payload }) => {
      state.state = LoadingStates.Succeeded
      state.data = payload
    })

    builder.addCase(fetchSharingGameTypes.pending, (state) => {
      state.state = LoadingStates.Pending
    })

    builder.addCase(fetchSharingGameTypes.rejected, (state) => {
      state.state = LoadingStates.Failed
    })
  },
})

const { reducer } = gameTypesSlice
export default reducer
