import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  SortingValues,
  SortingOrders,
  sortingValuesKeys,
  sortingOrdersKeys,
} from '../../../constants/statistics/sorting'
import reduceInputData from '../../../functions/reduceInputData'
import { StateType } from '../../../Input/InputStateType'
import { getActiveAccountName, getStatistics } from '../../../selectorFunctions'
import { fetchStatistics } from '../../../slices/statisticsSlice'
import queryString from 'query-string'
import Main from './Main'
import { useHistory, useLocation } from 'react-router'
import useLocalStorage from 'react-use-localstorage'
import { useGameTypes } from '../../../hooks'
import { useStatisticsUpdate } from '../../../hooks/useStatisticsUpdate'

const Statistics = () => {
  const dispatch = useDispatch()
  const [inputValue, setInputValue] = useState<StateType>({})
  const location = useLocation()
  const history = useHistory()
  const statisticsData = useSelector(getStatistics())
  const [selectedButtonValues, setSelectedButtonValues] = useLocalStorage('selectedButtonValues')
  const gameTypes = useGameTypes()
  const leagueName = useSelector(getActiveAccountName())
  useStatisticsUpdate(inputValue)

  useEffect(() => {
    const reduced = reduceInputData(inputValue)
    if (reduced.gameTypes) {
      const gameInterval = reduced.gameInterval ? reduced.gameInterval : ''
      let sortBy = SortingValues.ELO
      let order = SortingOrders.DESC

      setSelectedButtonValues(
        JSON.stringify({ gameType: reduced.gameTypes, interval: reduced.gameInterval })
      )

      if (gameInterval) {
        sortBy = SortingValues.GAMES_COUNT
      }

      dispatch(
        fetchStatistics({
          gameTypeId: reduced.gameTypes,
          interval: gameInterval,
          sortBy,
          order,
        })
      )
      history.push(`?order=${order}&sortBy=${sortBy}`)
    }
  }, [inputValue]) // eslint-disable-line

  useEffect(() => {
    const queryStringObj = queryString.parse(location.search)
    const reduced = reduceInputData(inputValue)
    const sortBy = queryStringObj['sortBy'] as string
    let order = queryStringObj['order'] as string

    if (sortBy === SortingValues.USER) {
      order = order === 'DESC' ? 'ASC' : 'DESC'
    }

    if (
      sortBy &&
      sortingValuesKeys.includes(sortBy) &&
      order &&
      sortingOrdersKeys.includes(order) &&
      reduced.gameTypes
    ) {
      dispatch(
        fetchStatistics({
          gameTypeId: reduced.gameTypes,
          interval: reduced.gameInterval ? reduced.gameInterval : '',
          sortBy: sortBy,
          order: order,
        })
      )
    }
  }, [location.search]) //eslint-disable-line

  return (
    <Main
      leagueName={leagueName}
      changingEnabled={true}
      inputValue={inputValue}
      statisticsSharing={false}
      statisticsData={statisticsData}
      selectedButtonValues={selectedButtonValues}
      setInputValue={setInputValue}
      gameTypes={gameTypes}
    />
  )
}

export default Statistics
