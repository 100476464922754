import { useSelector } from 'react-redux'
import { Route } from 'react-router'
import styled from 'styled-components'

import { getActiveAccountName } from '../../../selectorFunctions'
import Card from './Card'
import OrderForm from './OrderForm'

const Management = () => {
  const accountName = useSelector(getActiveAccountName())

  return (
    <>
      <Route path="/management/billing-info" component={OrderForm} />
      <Header>Správa {accountName}</Header>
      <Content>
        <Card icon="billing-info" text="PLATEBNÍ ÚDAJE" to="/management/billing-info" />
        <Card icon="invoices" text="FAKTURY" to="/management/invoices" />
        <Card icon="manage" text="SPRAVOVAT LIGU" to="/management/manage" />
      </Content>
    </>
  )
}

export default Management

const Header = styled.header`
  box-sizing: border-box;
  font-size: 2.875em;
  line-height: 1.17391304348;
  letter-spacing: 0.00565217391em;
  font-family: 'Roboto Condensed';
  font-weight: bold;
  width: 100%;
  padding: 1.5652173913em 1.21739130435em 1.21739130435em;
  border-bottom: 1px solid #e6e6e6;
  color: #2a2a2a;

  @media (max-width: 768px) {
    font-size: 2em;
  }
`

const Content = styled.div`
  padding: 2.625em 7.125em;
  display: flex;
  flex-wrap: wrap;
  gap: 2.25em;
  justify-content: space-around;
  max-width: 52.5em;

  @media (max-width: 768px) {
    padding: 2.625em;
  }
`
