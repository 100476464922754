import styled, { css } from 'styled-components'

export const NarrowInner = styled.div`
  margin: auto 5.75em;
  width: 17.5em;

  @media (max-width: 31.25em) {
    margin: 0 3em;
    width: calc(100% - 6em);
  }
`

export const Label = styled.label`
  color: #979797;
  font-size: 0.875em;
  line-height: 1.14285714286;
`

export const Title = styled.p`
  font-size: 1.25em;
  line-height: 1.2;
  padding: 1.6em 1.6em 0.8em;
  color: #2a2a2a;
  letter-spacing: 0.014em;
  font-weight: bold;
  margin: 0;
`

export const Back = styled.button`
  color: #ff8c1a;
  font-size: 0.75em;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1.16666666667;
`

export const Continue = styled.button`
  margin: 2.72222222222em auto 1.77777777778em;
  color: #bebebe;
  font-weight: bold;
  font-size: 1.125em;
  letter-spacing: 0;
  display: grid;
  place-content: center;
  width: 9.33333333333em;
  height: 2.66666666667em;
  background: transparent;
  border: none;
  padding: 0;
  transition: color 0.2s ease-in-out;

  :after {
    content: 'Pokračovat >';
  }

  ${({ onClick }) =>
    onClick &&
    css`
      color: #ff8c1a;
      cursor: pointer;

      :hover {
        color: #c86809;
      }
    `}
`
