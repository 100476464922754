import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import styled from 'styled-components'
import Checkbox from '../../../../Input/Checkbox'
import ButtonStyles from '../../../layout/ButtonStyles'
import { useDispatch, useSelector } from 'react-redux'
import {
  generateStatisticsShareLink,
  setGeneratedShareLinkStateToEmpty,
} from '../../../../slices/statisticsSlice'
import { getStatistics } from '../../../../selectorFunctions'
import { LoadingStates } from '../../../../constants/sliceTypes/loadingStates'
import { SHARE_LINK_PREFIX } from '../../../../constants/statistics/shareLink'
import CopyInput from '../../../layout/CopyInput'

interface Props {
  setGenerateLinkActive: Dispatch<SetStateAction<boolean>>
  generateLinkActive: boolean
  mobile?: boolean
  gameTypeId: string
  interval: string
}

const Main = ({
  setGenerateLinkActive,
  generateLinkActive,
  mobile,
  gameTypeId,
  interval,
}: Props) => {
  const [checked, setChecked] = useState(false)
  const dispatch = useDispatch()
  const { generatedShareLink, generatedShareLinkState } = useSelector(getStatistics())
  const shareLink = `${SHARE_LINK_PREFIX}${generatedShareLink}`

  function handleGenerate() {
    let toSend = {
      gameTypeId,
      changingEnabled: checked,
    }

    if (interval) {
      //@ts-ignore
      toSend.interval = interval
    }

    dispatch(generateStatisticsShareLink(toSend))
  }

  useEffect(() => {
    if (generatedShareLinkState === LoadingStates.Succeeded) {
      setGenerateLinkActive(true)
      dispatch(setGeneratedShareLinkStateToEmpty())
    }
  }, [generatedShareLinkState]) //eslint-disable-line

  return (
    <Container>
      {gameTypeId ? (
        <>
          <Checkbox
            checked={checked}
            setChecked={setChecked}
            marginRight={mobile ? 2.5 : 0}
            text="Povolit změny časového období a herního módu"
          />
          <Center>
            <GenerateLinkButton
              loading={generatedShareLinkState === LoadingStates.Pending}
              onClick={handleGenerate}
            >
              Generovat odkaz
            </GenerateLinkButton>
            {generateLinkActive && <CopyInput value={shareLink} />}
          </Center>
        </>
      ) : (
        <Center>Zatím nejsi připojen do žádné ligy !</Center>
      )}
    </Container>
  )
}

export default Main

const GenerateLinkButton = styled.button`
  ${ButtonStyles}
  height: 3em;
  width: 13.75em;
`

const Center = styled.div`
  width: 100%;
  display: flex;
  margin-top: 3.5em;
  flex-direction: column;
  align-items: center;
  margin-left: -2em;
`

const Container = styled.div`
  width: 100%;
  padding: 2em;
`
