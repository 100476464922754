import { useState } from 'react'
import styled from 'styled-components'

import { useUserIds } from '../../../hooks'
import MainTitle from '../../styled/MainTitle'
import AddUserPopUp from './AddUserPopUp'
import UsersSection from './UsersSection'

const Main = () => {
  const [visible, setVisible] = useState(false)

  const userIds = useUserIds()

  return (
    <>
      {userIds && <AddUserPopUp visible={visible} setVisible={setVisible} />}
      <Container>
        <MainTitleSection>
          <MainTitle>Uživatelé</MainTitle>
          {userIds && <AddButton onClick={() => setVisible(true)} />}
        </MainTitleSection>
        {userIds && <UsersSection userIds={userIds} />}
      </Container>
    </>
  )
}

export default Main

const Container = styled.div`
  padding-top: 1em;
`

const MainTitleSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const AddButton = styled.button`
  background: url('/img/add-icon.svg') no-repeat center;
  outline: none;
  border-radius: 0.5em;
  border: none;
  background-color: #ff8c1a;
  height: 3.2em;
  width: 3.2em;
  cursor: pointer;
  margin-right: 3em;

  &:hover {
    background-color: #c86809;
  }
`
