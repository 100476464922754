import { useState } from 'react'
import styled from 'styled-components'

import FullPageOverlay from '../../../styled/FullPageOverlay'
import { PathKeys } from '../Icon'
import Menu from '../Menu'

interface Props {
  activePath: PathKeys
}

const Main = (props: Props) => {
  const [active, setActive] = useState(false)

  return (
    <>
      {active && (
        <FullPageOverlay align="left" setActive={setActive}>
          <Menu mobile={true} activePath={props.activePath} />
        </FullPageOverlay>
      )}
      <TopContainer>
        <LeftSection>
          <Hamburger onClick={() => setActive(true)} src="./img/hamburger.svg" />
          <Logo src="./img/foosapp-logo-m.svg" />
        </LeftSection>
      </TopContainer>
    </>
  )
}

export default Main

const TopContainer = styled.div`
  width: 100%;
  height: 3.5em !important;
  display: flex;
  justify-content: flex-start;
  padding-left: 1.5em;
  background-color: white;
`

const LeftSection = styled.section`
  display: flex;
  align-items: center;
`

const Logo = styled.img``

const Hamburger = styled.img`
  margin-right: 1.8em;
  cursor: pointer;
`
