import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { userData } from '../../../../constants/forms/data'
import { FormType } from '../../../../constants/forms/types'
import handleChange from '../../../../functions/handleChange'
import SelectInput from '../../../../Input'
import { HandleChangeEvent } from '../../../../Input/HandleChangeEvent'
import { StateType } from '../../../../Input/InputStateType'
import ButtonStyles from '../../../layout/ButtonStyles'
import FullPageOverlay from '../../../styled/FullPageOverlay'
import Text from '../../../styled/Text'
import AvatarDropzone from './AvatarDropzone'
import Title from './styled/Title'

interface Props {
  avatar: string
  getUserInfo: (data: StateType, image: File | undefined) => void
  handleSubmit: () => void
  prefilledData?: StateType
  withDelete: boolean
  active: boolean
  titleText: string
  onPopUpClose?: () => void
  loading: boolean
  sendButtonText: string
}

const Main = ({
  avatar,
  getUserInfo,
  handleSubmit,
  prefilledData,
  withDelete,
  onPopUpClose,
  titleText,
  active,
  loading,
  sendButtonText,
}: Props) => {
  const [inputValue, setInputValue] = useState<StateType>({})
  const [image, setImage] = useState<any>()
  let history = useHistory()

  useEffect(() => {
    getUserInfo(inputValue, image)
  }, [inputValue, image]) // eslint-disable-line

  function getImage(image: File) {
    //@ts-ignore
    setImage(image)
  }

  useEffect(() => {
    if (prefilledData && Object.keys(prefilledData).length !== 0) {
      setInputValue(prefilledData)
    }
  }, []) // eslint-disable-line

  function handleDelete() {
    history.replace('?del')
  }

  return active ? (
    <FullPageOverlay>
      <FormContainer>
        <Title>{titleText}</Title>
        {withDelete && <Close onClick={onPopUpClose} />}
        <AvatarDropzone registration={!withDelete} avatar={avatar} getImage={getImage} />
        {withDelete && (
          <DeleteAvatar>
            <Text color="#2a2a2a" onClick={handleDelete}>
              Smazat avatara
            </Text>
          </DeleteAvatar>
        )}
        {userData.map((formInput: FormType) => (
          <SelectInput
            key={formInput.name}
            formInput={formInput}
            value={(inputValue[formInput.name] && inputValue[formInput.name].value) || ''}
            isInvalid={inputValue[formInput.name] && inputValue[formInput.name].isInvalid}
            handleChange={(formInput, e: HandleChangeEvent) =>
              handleChange(formInput, e, inputValue, setInputValue)
            }
          />
        ))}
        <SendButton loading={loading} onClick={() => handleSubmit()}>
          {sendButtonText}
        </SendButton>
      </FormContainer>
    </FullPageOverlay>
  ) : (
    <></>
  )
}

export default Main

const SendButton = styled.button`
  ${ButtonStyles}
  width: 10.5em;
  height: 3em;
  margin-top: 0.8em;
`

const DeleteAvatar = styled.div`
  cursor: pointer;
  font-size: 1em;
  margin-bottom: 0.5em;
`

const FormContainer = styled.div`
  width: 21.5em;
  height: 27em;
  border-radius: 6px;
  background-color: white;
  position: relative;
  z-index: 20;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 8px 0 rgba(42, 42, 42, 0.6);
`

const Close = styled.div`
  background-image: url('./img/close.svg');
  background-repeat: no-repeat;
  width: 1em;
  height: 1em;
  margin-bottom: -1em;
  position: relative;
  top: -3.5em;
  left: 9.4em;
  cursor: pointer;
`
