import { useMediaQuery } from 'react-responsive'

import { PathKeys } from './Icon'
import Main from './Mobile/Main'
import Menu from './Menu'

interface Props {
  activePath: PathKeys
}

const UserMenu = (props: Props) => {
  const smallDevice = useMediaQuery({
    query: '(max-width: 768px)',
  })

  return smallDevice ? <Main {...props} /> : <Menu mobile={false} {...props} />
}

export default UserMenu
