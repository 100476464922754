import styled from 'styled-components'

interface Props {
  isMenuOpen: boolean
}

export default styled.section<Props>`
  display: flex;
  width: ${({ isMenuOpen }) => (isMenuOpen ? 'calc(100% - 16.25em)' : 'calc(100% - 3.5em)')};
  overflow: hidden;
  min-height: 100vh;
  flex-direction: column;
  margin-left: ${({ isMenuOpen }) => (isMenuOpen ? '16.25em' : '3.5em')};

  @media screen and (max-width: 768px) {
    width: 100%;
    margin-left: 0;
  }
`
