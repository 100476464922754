import logger from 'redux-logger'
import thunk from 'redux-thunk'
import { rootReducer } from '../reducers'
import { configureStore } from '@reduxjs/toolkit'

const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk, logger],
})

export default store

export type RootState = ReturnType<typeof store.getState>
