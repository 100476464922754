import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { MenuLinksType } from '../../../constants/Links'
import MenuLinkContainer from './MenuLinkContainer'
import Icon from './Icon'

interface Props {
  isActive: boolean
  item: MenuLinksType
}

const StyledMenuLink = ({ item, isActive }: Props) => (
  <StyledLink to={item.url}>
    <MenuLinkContainer isActive={isActive}>
      <Icon path={item.path} />
      <StyledMenuItem>{item.text}</StyledMenuItem>
    </MenuLinkContainer>
  </StyledLink>
)

export default StyledMenuLink

const StyledLink = styled(Link)`
  text-decoration: none;
`

const StyledMenuItem = styled.span`
  display: flex;
`
