import { ChangeEvent } from 'react'
import styled, { css } from 'styled-components'
import { FormType } from '../constants/forms/types'
import { HandleChangeEvent } from './HandleChangeEvent'

interface Props {
  formInput: FormType
  isInvalid: boolean
  value: string
  placeholder: string
  handleChange: (item: FormType, event: HandleChangeEvent) => void
}

const TextInput = ({ formInput, isInvalid, handleChange, value, placeholder }: Props) => (
  <Input
    isInvalid={isInvalid}
    value={value}
    onChange={(event: ChangeEvent<HTMLInputElement>) => handleChange(formInput, event)}
    placeholder={placeholder}
    width={formInput.width}
    height={formInput.height}
    type={formInput.type.toLowerCase()}
    maxLength={20}
  />
)
export default TextInput

interface InputProps {
  isInvalid: boolean
  width?: number
  height?: number
}

const Input = styled.input<InputProps>`
  background-color: white;
  border: 1px solid #979797;
  line-height: 1.1875em;
  width: ${(props) => (props.width ? props.width : '17.5')}em;
  height: ${(props) => (props.height ? props.height : '3')}em;
  display: inline-block;
  border-radius: 6px;
  font-family: Roboto Condensed;
  font-size: 0.9375em;
  font-weight: 500;
  padding-left: 1em;
  padding-right: 1em;
  letter-spacing: -0.01875em;
  line-height: 1.125em;
  color: #c8c8c8;

  &:focus {
    color: #2a2a2a;
    border-color: #2a2a2a;
    outline: none;
  }

  &::placeholder {
    color: #c8c8c8;
  }

  ${({ isInvalid }) =>
    isInvalid &&
    css`
      color: #ff311a;
      border-color: #ff311a;

      &::placeholder {
        color: #ff311a;
      }

      &:focus {
        color: #ff311a;
        border-color: #ff311a;
        outline: none;
      }
    `};
`
