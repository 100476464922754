import { FormType, TYPES } from '../constants/forms/types'
import { HandleChangeEvent } from './HandleChangeEvent'
import styled from 'styled-components'
import TextInput from './TextInput'
import Select, { SelectOptions } from './Select'

interface Props {
  formInput: FormType
  isInvalid: boolean
  value: string
  handleChange: (item: FormType, event: HandleChangeEvent) => void
  options?: SelectOptions[]
}

const SelectInput = (props: Props) => {
  switch (props.formInput.type) {
    case TYPES.PASSWORD:
    case TYPES.TEXT:
      return (
        <InputContainer>
          {/*<Text text={props.formInput.text} />*/}
          <TextInput {...props} placeholder={props.formInput.placeholder} />
        </InputContainer>
      )

    case TYPES.SELECT:
      return (
        <Select
          formInput={props.formInput}
          handleChange={props.handleChange}
          options={props.options}
        />
      )

    default:
      return <></>
  }
}
export default SelectInput

const InputContainer = styled.div`
  margin-bottom: 1em;
`
