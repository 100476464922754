import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { normalize } from 'normalizr'
import { statisticsSchema } from '../constants/schemas'
import { LoadingStates } from '../constants/sliceTypes/loadingStates'
import {
  GenerateShareLinkParams,
  StatisticsParams,
  StatisticsSharingParams,
  StatisticsState,
  StatisticsType,
} from '../constants/sliceTypes/statisticsSlice'
import fetch from '../functions/fetch'
import { addUsers } from './userSlice'
import createRequestUri from '../functions/createRequestUri'
import { Methods } from '../functions/fetch'

const statisticsState: StatisticsState = {
  state: '',
  data: [],
  generatedShareLink: '',
  generatedShareLinkState: '',
  shareLink: {},
}

export const fetchStatistics = createAsyncThunk(
  'statistics/fetch',
  async (statisticsParams: StatisticsParams, { dispatch }) => {
    const { gameTypeId, ...rest } = statisticsParams
    const data = await fetch(
      `game-types/${gameTypeId}/statistics${
        createRequestUri({ ...rest }) ? `?${createRequestUri({ ...rest })}` : ''
      }`
    )
    const users = normalize(data, statisticsSchema).entities
    dispatch(addUsers(users))
    const statisticsData = data.map((el: StatisticsType) => {
      return { statistics: el.statistics, user: el.user._id }
    })

    return statisticsData
  }
)

export const fetchSharingStatistics = createAsyncThunk(
  'statistics/fetchSharing',
  async (statisticsParams: StatisticsSharingParams, { dispatch }) => {
    const data = await fetch(
      `embeds/statistics${
        createRequestUri(statisticsParams) ? `?${createRequestUri(statisticsParams)}` : ''
      }`,
      { withToken: false }
    )

    const users = normalize(data.statistics, statisticsSchema).entities
    dispatch(addUsers(users))
    const statisticsData = data.statistics.map((el: StatisticsType) => {
      return { statistics: el.statistics, user: el.user._id }
    })

    return { statistics: statisticsData, shareLink: data.shareLink }
  }
)

export const generateStatisticsShareLink = createAsyncThunk(
  'statistics/generateShareLink',
  async (generateShareLinkParams: GenerateShareLinkParams) => {
    const { gameTypeId, ...rest } = generateShareLinkParams
    return await fetch(`game-types/${gameTypeId}/statistics/share`, {
      method: Methods.Post,
      data: { ...rest },
    })
  }
)

const statisticsSlice = createSlice({
  name: 'statistics',
  initialState: statisticsState,
  reducers: {
    setGeneratedShareLinkStateToEmpty(state) {
      state.generatedShareLinkState = LoadingStates.Empty
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchStatistics.fulfilled, (state, { payload, meta }) => {
      state.params = meta.arg
      state.state = LoadingStates.Succeeded
      state.data = payload
    })

    builder.addCase(fetchStatistics.pending, (state) => {
      state.state = LoadingStates.Pending
    })

    builder.addCase(fetchStatistics.rejected, (state) => {
      state.state = LoadingStates.Failed
    })

    builder.addCase(fetchSharingStatistics.fulfilled, (state, { payload }) => {
      state.state = LoadingStates.Succeeded
      state.data = payload.statistics
      state.shareLink = payload.shareLink
    })

    builder.addCase(fetchSharingStatistics.pending, (state) => {
      state.state = LoadingStates.Pending
    })

    builder.addCase(fetchSharingStatistics.rejected, (state) => {
      state.state = LoadingStates.Failed
    })

    builder.addCase(generateStatisticsShareLink.fulfilled, (state, { payload }) => {
      state.generatedShareLinkState = LoadingStates.Succeeded
      state.generatedShareLink = payload.url
    })

    builder.addCase(generateStatisticsShareLink.pending, (state) => {
      state.generatedShareLinkState = LoadingStates.Pending
    })

    builder.addCase(generateStatisticsShareLink.rejected, (state) => {
      state.generatedShareLinkState = LoadingStates.Failed
    })
  },
})

const { reducer, actions } = statisticsSlice
export const { setGeneratedShareLinkStateToEmpty } = actions
export default reducer
