import { PathKeys } from '../components/layout/UserMenu/Icon'

export interface MenuLinksType {
  path: PathKeys
  url: string
  text: string
}

const userMenuLinks: MenuLinksType[] = [
  { path: PathKeys.Statistics, url: '/statistics', text: 'Statistiky' },
  { path: PathKeys.Users, url: '/users', text: 'Uživatelé' },
  { path: PathKeys.Management, url: '/management', text: 'Správa' },
]

export default userMenuLinks
