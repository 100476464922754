import styled from 'styled-components'

interface LogoContainerProps {
  imageUrl: string
}

export const LogoContainer = styled.div<LogoContainerProps>`
  border-radius: 50%;
  width: 2em;
  min-width: 2em;
  height: 2em;
  margin: 0 0.55em;
  background-image: url(${({ imageUrl }) => imageUrl});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`

interface IconProps {
  $rotate?: boolean
}

export const Icon = styled.img<IconProps>`
  transition: 0.2s;
  margin: 0 1em 0 auto;
  transform: rotate(${({ $rotate }) => (!$rotate ? 0 : 180)}deg);
  cursor: pointer;
`
