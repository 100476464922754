export enum Methods {
  Post = 'POST',
  Get = 'GET',
  Put = 'PUT',
  Delete = 'DELETE',
}

const fetchApi = async (
  path: string,
  {
    method = Methods.Get,
    withToken = true,
    data,
    asFormData,
  }: {
    method?: Methods
    withToken?: boolean
    data?: object
    asFormData?: boolean
  } = {}
) => {
  let structure: any = {}

  if (withToken) {
    const token = localStorage.getItem('token')

    if (token)
      structure.headers = {
        Authorization: `Bearer ${token}`,
      }
  }

  if (data) {
    structure.body = asFormData ? data : JSON.stringify(data)
    if (!asFormData) {
      structure.headers.Accept = 'application/json'
      structure.headers['Content-Type'] = 'application/json'
    }
  }

  const response = await fetch(`${process.env.REACT_APP_API_URL}${path}`, {
    method: method,
    ...structure,
  })

  if (response.status === 204) {
    return 'OK'
  } else if (!response.ok) {
    window.alert(response.status)
    const err = await response.json()
    throw Error(err)
  } else {
    return await response.json()
  }
}

export default fetchApi
