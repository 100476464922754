import { SortingOrders, SortingValues } from '../constants/statistics/sorting'
import queryString from 'query-string'
import styled from 'styled-components'
import { Location } from 'history'

export default function handleActiveSorting(
  itemText: string,
  location: Location,
  disabledSorting?: boolean
) {
  if (disabledSorting) {
    return
  }

  //@ts-ignore
  const sortingValue = SortingValues[itemText]
  const queryStringObj = queryString.parse(location.search)
  let sortUp = ''
  let sortDown = ''

  if (sortingValue === queryStringObj['sortBy'] && queryStringObj['order'] === SortingOrders.ASC) {
    sortUp = '-green'
    sortDown = ''
  } else if (
    sortingValue === queryStringObj['sortBy'] &&
    queryStringObj['order'] === SortingOrders.DESC
  ) {
    sortDown = '-green'
    sortUp = ''
  }

  return (
    <SortingButtons>
      <SortImageUp src={`/img/sort-up${sortUp}.svg`} />
      <SortImageDown src={`/img/sort-down${sortDown}.svg`} />
    </SortingButtons>
  )
}

const SortImageUp = styled.img`
  position: relative;
  margin-bottom: 0.2em;
`

const SortImageDown = styled.img`
  cursor: pointer;
`

const SortingButtons = styled.div`
  display: flex;
  width: 1em;
  height: 1em;
  float: left;
  flex-direction: column;
  margin-right: 0.4em;
  cursor: pointer;
  position: relative;
  left: 0.2em;
`
