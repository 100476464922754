import getEnumKeys from '../../functions/getEnumKeys'

export enum SortingValues {
  USER = 'USER',
  ELO = 'ELO',
  GAMES_COUNT = 'GAMES_COUNT',
  TIME = 'TIME',
  TIME_PER_GAME = 'TIME_PER_GAME',
  WON_GAME = 'WON_GAME',
  FLEXIBILITY = 'FLEXIBILITY',
  WON_GAME_ATTACKER = 'WON_GAME_ATTACKER',
  WON_GAME_DEFENDER = 'WON_GAME_DEFENDER',
  ACTION = 'ACTION',
}

export enum SortingValuesText {
  'USER' = 'Jméno a příjmení',
  'ELO' = 'ELO',
  'GAMES_COUNT' = 'Počet her',
  'TIME' = 'Čas',
  'TIME_PER_GAME' = 'Čas/Zápas',
  'WON_GAME' = 'Výhry',
  'FLEXIBILITY' = 'Vyváženost',
  'WON_GAME_ATTACKER' = 'Výhry/Útočník',
  'WON_GAME_DEFENDER' = 'Výhry/Obránce',
  'ACTION' = 'Góly',
}

export enum SortingOrders {
  DESC = 'DESC',
  ASC = 'ASC',
}

export const sortingValuesKeys = getEnumKeys(SortingValues)
export const sortingOrdersKeys = getEnumKeys(SortingOrders)

export type SortingOrder = typeof SortingOrders.ASC | typeof SortingOrders.DESC
