import styled from 'styled-components'
import { headerData } from '../../../../constants/statistics/headerData'
import { useHistory, useLocation } from 'react-router'
import { renderHeaderItems } from './renderHeaderItems'

interface Props {
  interval?: string
}

const Header = ({ interval }: Props) => {
  const location = useLocation()
  const history = useHistory()

  return <TableHead>{renderHeaderItems(headerData, false, location, history, interval)}</TableHead>
}

export default Header

const TableHead = styled.thead``
