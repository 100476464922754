import styled from 'styled-components'
import Main from './Main'

const Login = () => {
  return (
    <Container>
      <Main />
    </Container>
  )
}

export default Login

const Container = styled.div`
  overflow-x: hidden;
  background-image: url('/img/background.png');
`
