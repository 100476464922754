import { Dispatch, SetStateAction, useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import PopUpMain from '../../../PopUp/Main'
import Main from './Main'
import Mobile from './Mobile'

interface Props {
  visible: boolean
  setVisible: Dispatch<SetStateAction<boolean>>
  gameTypeId: string
  interval: string
}

const SharePopUp = ({ visible, setVisible, gameTypeId, interval }: Props) => {
  const [generateLinkActive, setGenerateLinkActive] = useState(false)

  const smaller = useMediaQuery({
    query: '(max-width: 900px)',
  })

  const smallDevice = useMediaQuery({
    query: '(max-width: 768px)',
  })

  return smallDevice ? (
    <Mobile
      setVisible={setVisible}
      visible={visible}
      generateLinkActive={generateLinkActive}
      setGenerateLinkActive={setGenerateLinkActive}
      gameTypeId={gameTypeId}
      interval={interval}
    />
  ) : (
    <PopUpMain
      size={{ height: generateLinkActive ? '23em' : '16em', width: smaller ? '46em' : '55em' }}
      setStateAction={setGenerateLinkActive}
      children={
        <Main
          gameTypeId={gameTypeId}
          interval={interval}
          generateLinkActive={generateLinkActive}
          setGenerateLinkActive={setGenerateLinkActive}
        />
      }
      setVisible={setVisible}
      titleText="NASTAVENÍ SDÍLENÍ"
      visible={visible}
    />
  )
}

export default SharePopUp
