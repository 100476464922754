import { ReactNode } from 'react'
import styled, { css } from 'styled-components'

import CheckboxIcon from './CheckboxIcon'

interface Props {
  label: ReactNode
  value: boolean
  className?: string
  disabled?: boolean
  onChange: (value: boolean) => void
}

const CheckboxInput = (props: Props) => (
  <Label className={props.className} disabled={props.disabled}>
    <Input
      type="checkbox"
      defaultChecked={props.value}
      disabled={props.disabled}
      onChange={() => props.onChange(!props.value)}
    />
    <CheckboxIcon />
    {props.label}
  </Label>
)

export default CheckboxInput

interface LabelProps {
  disabled?: boolean
}

const Label = styled.label<LabelProps>`
  position: relative;
  overflow: hidden;
  display: flex;
  cursor: pointer;
  color: #2a2a2a;
  font-family: 'Roboto Condensed';
  letter-spacing: 0.005625em;

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;

      a {
        pointer-events: all;
      }
    `}
`

const Input = styled.input`
  position: absolute;
  top: -10em;

  :checked + svg path {
    opacity: 1;
  }

  :hover + svg,
  :focus-visible + svg {
    rect {
      stroke: #787878;
    }
  }

  :disabled + svg {
    opacity: 0.5;

    rect {
      stroke: #bebebe;
    }
  }
`
