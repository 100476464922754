import { FormType, TYPES } from './types'

export const userData: FormType[] = [
  {
    text: 'Jméno:',
    placeholder: 'Jméno',
    type: TYPES.TEXT,
    required: true,
    name: 'name',
  },
  {
    text: 'Příjmení:',
    placeholder: 'Příjmení',
    type: TYPES.TEXT,
    required: true,
    name: 'surname',
  },
]

export const StatisticsSelects: FormType[] = [
  {
    type: TYPES.SELECT,
    name: 'gameTypes',
    required: false,
    width: 13.75,
    mobileWidth: 10,
  },
  {
    type: TYPES.SELECT,
    name: 'gameInterval',
    required: false,
    width: 9,
    mobileWidth: 6.7,
  },
]
