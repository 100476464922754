import styled from 'styled-components'

const CheckboxIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
    <g fill="none" fillRule="evenodd">
      <rect width="18" height="18" x="1" y="1" stroke="#bebebe" strokeWidth="2" rx="2" />
      <path fill="#40770D" d="m7.189 13.939-3.128-3.128L3 11.872l4.189 4.189 9-9L15.127 6z" />
    </g>
  </Svg>
)

export default CheckboxIcon

const Svg = styled.svg`
  width: 1.25em;
  min-width: 1.25em;
  height: 1.25em;
  min-height: 1.25em;
  margin: 0 1.5em 1.5em 0;

  path {
    opacity: 0;
  }

  &,
  rect,
  path {
    transition: all 0.2s ease-in-out;
  }
`
