import styled from 'styled-components'
import ButtonStyles from '../../layout/ButtonStyles'
import { useAuth0 } from '@auth0/auth0-react'

const Main = () => {
  const { loginWithRedirect } = useAuth0()

  return (
    <Container>
      <Logo src="/img/big-logo.png" />
      <RegisterButton onClick={() => loginWithRedirect({ screen_hint: 'signup' })}>
        Registrace
      </RegisterButton>
      <AlreadyHaveAccountContainer>
        <Text>Máte již účet?&nbsp;</Text>
        <LoginTextButton onClick={() => loginWithRedirect()}>Přihlašte se</LoginTextButton>
      </AlreadyHaveAccountContainer>
    </Container>
  )
}

export default Main

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
`

const Logo = styled.img`
  margin-bottom: 3em;
`

const RegisterButton = styled.button`
  ${ButtonStyles}
  width: 17.5rem;
  height: 3rem;
  margin-bottom: 1em;
`

const Text = styled.span`
  color: black;
  font-family: 'Roboto Condensed';
  font-size: 1.0625rem;
  font-weight: bold;
`

const LoginTextButton = styled(Text)`
  color: #ff8c1a;
  cursor: pointer;
`

const AlreadyHaveAccountContainer = styled.div``
