import { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { getUserById } from '../../../../selectorFunctions'
import UserWithAvatar from '../../../layout/User'
import Actions from './Actions'

interface Props {
  id: string
}

const UserRow = ({ id }: Props) => {
  const [hovered, setHovered] = useState(false)
  const user = useSelector(getUserById(id))

  function handleMouseOver() {
    if (!hovered) {
      setHovered(true)
    }
  }

  function handleMouseOut() {
    if (hovered) {
      setHovered(false)
    }
  }

  return (
    <Container onMouseEnter={handleMouseOver} onMouseLeave={handleMouseOut}>
      <Padding>
        <UserWithAvatar user={user} />
      </Padding>
      <Actions hovered={hovered} setHovered={setHovered} userId={user._id} />
    </Container>
  )
}

export default UserRow

const Padding = styled.div`
  padding-left: 4em;

  @media (max-width: 768px) {
    padding-left: 1em;
  }
`

const Container = styled.div`
  width: 100%;
  height: 4em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;

  :nth-child(odd) {
    background: #f8f8f8;
  }
`
