import { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getGameTypes, getStatistics } from '../../../../selectorFunctions'
import { fetchSharingGameTypes } from '../../../../slices/gameTypesSlice'
import Main from '../Main'
import { useHistory, useLocation } from 'react-router'
import { fetchSharingStatistics } from '../../../../slices/statisticsSlice'
import { StateType } from '../../../../Input/InputStateType'
import styled from 'styled-components'
import {
  ShareLink,
  StatisticsSharingParams,
} from '../../../../constants/sliceTypes/statisticsSlice'
import {
  SortingValues,
  SortingOrders,
  sortingValuesKeys,
  sortingOrdersKeys,
} from '../../../../constants/statistics/sorting'
import reduceInputData from '../../../../functions/reduceInputData'
import queryString from 'query-string'
import { useStatisticsUpdate } from '../../../../hooks/useStatisticsUpdate'

const Share = () => {
  const dispatch = useDispatch()
  const gameTypes = useSelector(getGameTypes())
  const location = useLocation()
  const history = useHistory()
  const splitted = location.pathname.split('/')
  const urlKey = splitted[2]
  const statisticsData = useSelector(getStatistics())
  const [inputValue, setInputValue] = useState<StateType>({})
  const shareLink = statisticsData.shareLink as ShareLink
  useStatisticsUpdate(inputValue, true)

  useEffect(() => {
    dispatch(fetchSharingGameTypes(urlKey))

    let sortBy = shareLink.interval ? SortingValues.GAMES_COUNT : SortingValues.ELO
    let order = SortingOrders.DESC
    dispatch(fetchSharingStatistics({ urlKey, sortBy, order }))
    history.push(`?order=${order}&sortBy=${sortBy}`)
  }, []) //eslint-disable-line

  useEffect(() => {
    const queryStringObj = queryString.parse(location.search)
    const reduced = reduceInputData(inputValue)
    let order = queryStringObj['order'] as string
    const sortBy = queryStringObj['sortBy'] as string

    if (sortBy === SortingValues.USER) {
      order = order === 'DESC' ? 'ASC' : 'DESC'
    }

    if (
      sortBy &&
      sortingValuesKeys.includes(sortBy) &&
      order &&
      sortingOrdersKeys.includes(order) &&
      reduced.gameTypes
    ) {
      let toSend: StatisticsSharingParams = {
        urlKey,
        sortBy,
        order,
      }

      if (shareLink.changingEnabled) {
        toSend.interval = reduced.gameInterval ? reduced.gameInterval : ''
        toSend.gameTypeId = reduced.gameTypes
      }

      dispatch(fetchSharingStatistics(toSend))
    }
  }, [location.search, inputValue]) //eslint-disable-line

  useEffect(() => {
    if (inputValue) {
      const reduced = reduceInputData(inputValue)
      let sortBy = reduced.gameInterval ? SortingValues.GAMES_COUNT : SortingValues.ELO
      let order = SortingOrders.DESC
      history.push(`?order=${order}&sortBy=${sortBy}`)
    }
  }, [inputValue]) //eslint-disable-line

  return (
    <Background>
      {shareLink.gameTypeId && (
        <Main
          leagueName={shareLink.accountName}
          defaultButtonValues={{
            interval: shareLink.interval || '',
            gameType: shareLink.gameTypeId,
          }}
          changingEnabled={shareLink.changingEnabled}
          gameTypes={gameTypes}
          statisticsSharing={true}
          statisticsData={statisticsData}
          setInputValue={setInputValue}
          inputValue={inputValue}
        />
      )}
    </Background>
  )
}

export default Share

const Background = styled.div`
  background-image: url('/img/background.png');
  min-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
`
