import styled from 'styled-components'

interface CloseProps {
  imageUrl: string
  top?: number
}

export default styled.div<CloseProps>`
  width: 1em;
  height: 1em;
  background-image: url('${({ imageUrl }) => imageUrl}');
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  margin-right: 1em;
  position: relative;
  top: ${({ top }) => (top ? top : 1)}em;
`
