import { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { useDropzone } from 'react-dropzone'
import { useHistory, useLocation } from 'react-router'

interface Props {
  avatar: string
  getImage: (image: File) => void
  registration: boolean
}

const AvatarDropzone = ({ avatar, getImage, registration }: Props) => {
  const [avatarImage, setAvatarImage] = useState([{ preview: avatar, default: true }])
  const location = useLocation()
  const delAvatar = location.search.includes('del')
  const history = useHistory()
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg, image/png, application/pdf',
    onDrop: (acceptedFiles) => {
      setAvatarImage(
        //@ts-ignore
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      )
    },
  })

  useEffect(() => {
    if (delAvatar) {
      setAvatarImage([{ preview: './img/no-avatar.svg', default: true }])
    }
  }, [location.search]) // eslint-disable-line

  useEffect(() => {
    if (!avatarImage[0].default) {
      if (delAvatar) history.push('?')
      getImage(acceptedFiles[0])
    }
  }, [avatarImage]) // eslint-disable-line

  return (
    <Container registration={registration}>
      <ImageCircle {...getRootProps()} imageUrl={avatarImage[0].preview}>
        <DropzoneInput {...getInputProps()} />
        <EditPencil />
      </ImageCircle>
    </Container>
  )
}

export default AvatarDropzone

interface ContainerProps {
  registration: boolean
}

const Container = styled.div<ContainerProps>`
  margin-bottom: ${({ registration }) => (registration ? 1.5 : 0.5)}em;
`

const dropzoneSize = css`
  height: 6.875em;
  width: 6.875em;
  border-radius: 50%;
`

interface ImageCircleProps {
  imageUrl: string
}

const ImageCircle = styled.div<ImageCircleProps>`
  ${dropzoneSize}
  border: 1px solid #9B9B9B;
  cursor: pointer;
  background-image: url(${({ imageUrl }) => imageUrl});
  background-position: center;
  background-size: cover;
  outline: none;
`

const DropzoneInput = styled.input`
  ${dropzoneSize}
  outline: none;
`

const EditPencil = styled.div`
  width: 2em;
  height: 2em;
  background-image: url('./img/edit-avatar-pencil.svg'), linear-gradient(to right, #52a00b, #52a00b);
  background-repeat: no-repeat;
  border-radius: 50%;
  background-position: center;
  position: relative;
  top: 5em;
  left: 4.55em;
`
