import { useSelector } from 'react-redux'
import styled from 'styled-components'

import {
  Statistics,
  StatisticsTypeUserString,
} from '../../../../constants/sliceTypes/statisticsSlice'

import { headerData, HeaderItem } from '../../../../constants/statistics/headerData'
import convertSecToTime from '../../../../functions/convertSecToTime'
import { getGameTypeByID } from '../../../../selectorFunctions'
import { ContainerProps, containerStyles } from './containerStyles'
import { itemStyles } from './itemStyles'

interface Props {
  item: StatisticsTypeUserString
  index: number
  interval?: string
  selectedGameTypeID: string
}

const Item = ({ item, index, interval, selectedGameTypeID }: Props) => {
  let numberOfStatisticsPerUser = interval ? 8 : 9
  let selectedGameType = useSelector(getGameTypeByID(selectedGameTypeID))

  if (!selectedGameTypeID || !selectedGameType[0]) return null

  let goalAction = selectedGameType[0].actions.find((el) => el.name === 'Goal')

  function getValue(statistic: Statistics) {
    const value = statistic.value ? statistic.value : 0
    const withPercent = ['WON_GAME', 'FLEXIBILITY', 'WON_GAME_ATTACKER', 'WON_GAME_DEFENDER']

    if (statistic.type === 'ACTION' && statistic.refId === goalAction?._id) {
      return statistic.value
    } else if (statistic.type === 'ACTION') {
      return 'N/A'
    }

    if (statistic.type === 'TIME' || statistic.type === 'TIME_PER_GAME') {
      return convertSecToTime(value)
    }

    if (withPercent.includes(statistic.type) && typeof statistic.value === 'number') {
      return `${statistic.value}%`
    }

    return value
  }

  const statistics = item.statistics.map((el) => {
    if (!el.value) {
      return { ...el, value: 'N/A' }
    } else {
      return { ...el }
    }
  })

  function renderStatistics(statistics: Statistics[]) {
    let indexNum = 0
    //@ts-ignore
    let headerDataKeys = headerData[1].items.map((el: HeaderItem) => {
      return el.textKey
    })

    headerDataKeys.push('ACTION')

    if (interval) {
      headerDataKeys.splice(0, 1)
    }

    let arr = []

    while (indexNum < numberOfStatisticsPerUser) {
      // eslint-disable-next-line
      let statistic = statistics.find((el) => {
        if (headerDataKeys[indexNum] === 'ACTION') {
          return el.type === 'ACTION' && goalAction?._id === el.refId
        }

        return el.type === headerDataKeys[indexNum]
      })

      if (statistic) {
        arr.push(statistic)
        indexNum++
      } else {
        arr.push({ type: 'N/A', value: 'N/A' })
        indexNum++
      }
    }

    return arr
  }

  const statisticsToMap = renderStatistics(statistics)

  return (
    <Container bgColor={index % 2 === 0 ? 'white' : '#F8F8F8'}>
      {statisticsToMap.map(
        (statistic, index) =>
          getValue(statistic) && (
            <Statistic key={index} index={index} numberOfStatistics={numberOfStatisticsPerUser}>
              {getValue(statistic)}
            </Statistic>
          )
      )}
    </Container>
  )
}

export default Item

const Container = styled.tr<ContainerProps>`
  ${containerStyles}
`

interface ItemProps {
  index: number
  numberOfStatistics: number
}

const Statistic = styled.td<ItemProps>`
  ${itemStyles}
  text-align: center;
  padding-left: ${({ index }) => (index === 0 ? 12 : 0)}em;
  padding-right: ${({ index, numberOfStatistics }) => (index === numberOfStatistics - 1 ? 4 : 0)}em;

  @media (max-width: 768px) {
    padding-left: ${({ index }) => (index === 0 ? 10 : 0)}em;
  }
`
