import styled, { css } from 'styled-components'

import { Path } from './Icon'

interface Props {
  isActive?: boolean
  background?: string
  hoverBackground?: string
}

const getActiveStyle = (hoverBackground?: string) => css`
  letter-spacing: 0.00555555555em;
  background: ${hoverBackground || '#52a00b'};
  font-weight: bold;

  ${Path} {
    fill: #2a2a2a;
  }
`

const MenuLinkContainer = styled.div<Props>`
  overflow: hidden;
  white-space: nowrap;
  color: white;
  font-family: 'Roboto Condensed';
  font-size: 1.125em;
  height: 3.5em;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  width: 100%;
  position: relative;
  z-index: 800;
  letter-spacing: 0.01666666666em;
  transition: background-color 0.2s ease-in-out;
  background: ${({ background }) => background || 'transparent'};

  ${({ isActive, hoverBackground }) => {
    const style = getActiveStyle(hoverBackground)

    return css`
      ${isActive && style}

      :hover {
        ${style}
      }
    `
  }}
`

export default MenuLinkContainer
