import styled from 'styled-components'

export default styled.span`
  color: #2a2a2a;
  font-family: Roboto Condensed;
  font-size: 1.25em;
  font-weight: bold;
  letter-spacing: 0.01em;
  line-height: 1.5em;
  margin-bottom: 1em;
  width: 80%;
`
