import { useContext } from 'react'
import styled, { css } from 'styled-components'
import { useSelector } from 'react-redux'

import StyledMenuLink from './StyledMenuLink'
import userMenuLinks, { MenuLinksType } from '../../../constants/Links'
import User from './User'
import Logout from './Logout'
import { getLoggedUser } from '../../../selectorFunctions'
import { MenuContext } from './MenuContextProvider'
import Leagues from './League'
import { PathKeys } from './Icon'

interface Props {
  activePath: PathKeys
  mobile: boolean
}

const Menu = ({ activePath, mobile }: Props) => {
  const loggedUserData = useSelector(getLoggedUser())
  const { isMenuOpen, setIsMenuOpen } = useContext(MenuContext)

  function handleMenuOpening() {
    setIsMenuOpen(!isMenuOpen)
  }

  return (
    <UserMenu isMenuOpen={isMenuOpen}>
      {!mobile && <CloseOpenIcon isMenuOpen={isMenuOpen} onClick={handleMenuOpening} />}
      <FlexContainer>
        <Links>
          <LogoContainer isMenuOpen={isMenuOpen}>
            <Logo isMenuOpen={isMenuOpen} mobile={mobile} img="./img/usermenu-logo.svg" />
          </LogoContainer>

          <Leagues activeLeagueWithBorder={!isMenuOpen} />

          {userMenuLinks.map((item: MenuLinksType, index) => (
            <StyledMenuLink item={item} key={index} isActive={item.path === activePath} />
          ))}
        </Links>
        <Links>
          <User
            username={`${loggedUserData.firstName} ${loggedUserData.lastName}`}
            avatar={
              loggedUserData && loggedUserData.avatar
                ? loggedUserData.avatar.S
                : './img/no-avatar.svg'
            }
          />
          <Logout />
        </Links>
      </FlexContainer>
    </UserMenu>
  )
}

export default Menu

interface UserMenuProps {
  isMenuOpen: boolean
}

const UserMenu = styled.section<UserMenuProps>`
  width: ${({ isMenuOpen }) => (!isMenuOpen ? '3.5' : '16.25')}em;
  position: fixed;
  z-index: 700;
  height: auto;
  min-height: 100%;
  background-color: #1e1e1e;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: width 0.2s;
`

const Links = styled.div``

interface LogoContainerProps {
  isMenuOpen: boolean
}

const LogoContainer = styled.div<LogoContainerProps>`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: ${({ isMenuOpen }) => (isMenuOpen ? 2 : 3)}em;
  margin-bottom: ${({ isMenuOpen }) => (isMenuOpen ? 5.8 : 5.7)}em;

  @media screen and (max-width: 768px) {
    margin-bottom: 2em;
  }
`

interface LogoProps {
  img: string
  mobile: boolean
  isMenuOpen: boolean
}

const Logo = styled.div<LogoProps>`
  width: 5.5em;
  height: 5em;
  background-image: url('${({ img }) => img}');
  background-size: cover;
  background-position: center;

  ${({ mobile, isMenuOpen }) =>
    (mobile || !isMenuOpen) &&
    css`
      width: 3.4em;
      height: 3.4em;
    `}
`

const FlexContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
`

const CloseOpenIcon = styled.button<UserMenuProps>`
  background-color: transparent;
  background-image: url('./img/usermenu-arrow-left.svg');
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  position: relative;
  z-index: 999;
  color: black;
  font-size: 1.4em;
  top: 0.6em;
  right: -10em;
  width: 2em;
  height: 1em;
  cursor: pointer;

  &:hover {
    right: -9.7em;
  }

  ${({ isMenuOpen }) =>
    !isMenuOpen &&
    css`
      display: flex;
      background-position: right center;
      width: 1.4em;
      right: -1.7em;
      height: 1.5em;
      border-radius: 0 0.2em 0.2em 0;
      background-color: #1e1e1e;
      background-image: url('./img/usermenu-arrow-right.svg');

      &:hover {
        right: -2em;
      }
    `}
`
