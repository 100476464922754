import { FC } from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { App } from './App'
import * as serviceWorker from './serviceWorker'
import { Provider } from 'react-redux'
import store from './store/configureStore'
import { BrowserRouter as Router } from 'react-router-dom'
import { Auth0Provider } from '@auth0/auth0-react'
import MenuContextProvider from './components/layout/UserMenu/MenuContextProvider'

const render = (Component: FC) =>
  ReactDOM.render(
    <Auth0Provider
      domain="nextapauth.eu.auth0.com"
      clientId="pdZlSLgHaZt7Qfy0tlcXRQCPVZNGTEMD"
      redirectUri={`${process.env.REACT_APP_APP_URL}auth0-callback`}
      audience="https://nextapauth.eu.auth0.com/api/v2/"
      cacheLocation="localstorage"
      useRefreshTokens={true}
    >
      <Provider store={store}>
        <MenuContextProvider>
          <Router>
            <Component />
          </Router>
        </MenuContextProvider>
      </Provider>
    </Auth0Provider>,
    document.getElementById('root')
  )

render(App)

// @ts-ignore
if (module.hot) {
  // @ts-ignore
  module.hot.accept('./App', () => {
    render(App)
  })
}

serviceWorker.unregister()
