import BaseStep from './BaseStep'
import { isValid, useTextInput, useValuesOnLeave, Values } from './inputHelpers'
import { Continue, NarrowInner } from './styled'
import TextInput from './TextInput'

const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

interface Props {
  values: Values
  onContinue: () => void
  onValues: (values: Values) => void
}

const Step1 = (props: Props) => {
  const data = {
    firstName: useTextInput(props.values.firstName),
    lastName: useTextInput(props.values.lastName),
    email: useTextInput(props.values.email, EMAIL_REGEX),
  }

  useValuesOnLeave(data, props.onValues)

  return (
    <BaseStep title="NADPIS">
      <NarrowInner>
        <TextInput {...data.firstName} label="Jméno" />
        <TextInput {...data.lastName} label="Příjmení" />
        <TextInput {...data.email} label="E-mail" />

        <Continue onClick={isValid(data) ? props.onContinue : undefined} />
      </NarrowInner>
    </BaseStep>
  )
}

export default Step1
