import styled from 'styled-components'

export default styled.span`
  color: #2a2a2a;
  font-family: Roboto Condensed;
  font-size: 2.875em;
  font-weight: bold;
  line-height: 3.375em;
  margin-top: 0.3em;
  margin-left: 1em;

  @media screen and (max-width: 768px) {
    font-size: 2em;
    margin-left: 0.5em;
  }
`
