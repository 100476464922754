import { StateItem, StateType } from '../Input/InputStateType'

function reduceInputData(inputValue: StateType) {
  return Object.entries(inputValue).reduce(
    (newValues: { [key: string]: string }, currentValue: [string, StateItem]) => {
      newValues[currentValue[0]] = currentValue[1].value
      return newValues
    },
    {}
  )
}

export default reduceInputData
