import styled from 'styled-components'
import FullPageOverlay from '../styled/FullPageOverlay'
import ButtonStyles from './ButtonStyles'

interface Props {
  messageText: string
  abortButtonText: string
  confirmButtonText: string
  handleConfirm: () => void
  handleClose: () => void
  visible: boolean
}

const ConfirmActionPopUp = ({
  messageText,
  abortButtonText,
  confirmButtonText,
  handleConfirm,
  handleClose,
  visible,
}: Props) => {
  if (!visible) {
    return <></>
  }

  return (
    <FullPageOverlay>
      <ConfirmContainer>
        <Text>{messageText}</Text>
        <Buttons>
          <AbortButton redColor={true} onClick={handleClose}>
            {abortButtonText}
          </AbortButton>
          <ConfirmButton onClick={handleConfirm}>{confirmButtonText}</ConfirmButton>
        </Buttons>
      </ConfirmContainer>
    </FullPageOverlay>
  )
}

export default ConfirmActionPopUp

const ConfirmContainer = styled.div`
  height: 11em;
  width: 29.5em;
  border-radius: 0.3em;
  background-color: white;
  box-shadow: 0 0 8px 0 rgba(42, 42, 42, 0.6);
  display: flex;
  padding: 2em;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    width: 15em;
    height: 22em;
  }
`

const Text = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 1.5em;
  color: #2a2a2a;
  font-family: Roboto Condensed;
  font-size: 1.25em;
  font-weight: bold;
  letter-spacing: 0.0175em;
  line-height: 1.5em;
`

const Buttons = styled.section`
  display: flex;
  flex-direction: row;
  width: 85%;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 2em;
  }
`

const AbortButton = styled.button`
  ${ButtonStyles}
  height: 3em;
  width: 10.75em;

  @media (max-width: 768px) {
    margin-bottom: 2em;
  }
`

const ConfirmButton = styled(AbortButton)``
