import { combineReducers } from 'redux'
import userSlice from '../slices/userSlice'
import gameTypesSlice from '../slices/gameTypesSlice'
import statisticsSlice from '../slices/statisticsSlice'
import { reducer as accountsSlice } from '../slices/accountsSlice'

export const rootReducer = combineReducers({
  accounts: accountsSlice,
  user: userSlice,
  gameTypes: gameTypesSlice,
  statistics: statisticsSlice,
})
