import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { getAccountById } from '../../../../selectorFunctions'
import { inset } from '../../../../utils/styles'
import MenuLinkContainer from '../MenuLinkContainer'
import { Icon, LogoContainer } from './styled'

interface Props {
  activeWithBorder: boolean
  accountId: string
  isActive: boolean
  onClick: () => void
}

const League = (props: Props) => {
  const account = useSelector(getAccountById(props.accountId))

  const LogoComponent =
    props.activeWithBorder && props.isActive ? StyledLogoContainer : LogoContainer

  return (
    <MenuLinkContainer onClick={props.onClick} background="#2b2b2b" hoverBackground="#333">
      <LogoComponent imageUrl={account.avatar?.S || './img/no-avatar.svg'} />
      {account.name}
      {props.isActive && <Icon src="./img/tick.svg" alt="" />}
    </MenuLinkContainer>
  )
}

export default League

const StyledLogoContainer = styled(LogoContainer)`
  position: relative;

  :after {
    ${inset('-3px')}
    content: "";
    position: absolute;
    border: 1px solid#ff8c1a;
    border-radius: 50%;
  }
`
