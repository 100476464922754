import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Avatar } from '../constants/sliceTypes/userSlice'
import { authLogin, fetchUsers } from './userSlice'

export interface Account {
  _id: string
  name: string
  avatar?: Avatar
  userCount: number
  userLimit: number
  defaultTableId: string
  defaultGameTypeId: string
  users?: string[]
}

interface AccountsState {
  activeAccountId?: string
  byId: {
    [key: string]: Account
  }
}

export const initialState: AccountsState = {
  activeAccountId: localStorage.getItem('activeAccountId') || undefined,
  byId: {},
}

const slice = createSlice({
  initialState,
  name: 'accounts',
  reducers: {
    setActiveAccountId: (state, action: PayloadAction<string>) => {
      localStorage.setItem('activeAccountId', action.payload)
      state.activeAccountId = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(authLogin.fulfilled, (state, action) => {
      const { accounts } = action.payload.entities.users[action.payload.result]

      if (!state.activeAccountId || !accounts.includes(state.activeAccountId)) {
        const [activeAccountId] = accounts

        localStorage.setItem('activeAccountId', activeAccountId)
        state.activeAccountId = activeAccountId
      }

      state.byId = { ...state.byId, ...action.payload.entities.accounts }
    })

    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      state.byId[state.activeAccountId!].users = action.payload.result
    })
  },
})

export const actions = slice.actions
export const reducer = slice.reducer
