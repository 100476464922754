import ReactSelect from 'react-select'
import { CSSProperties } from 'react'

import { FormType } from '../constants/forms/types'
import { HandleChangeEvent } from './HandleChangeEvent'

interface SelectOption {
  value: string
  label: string
}

export interface SelectOptions {
  name: string
  options: SelectOption[]
}

interface Props {
  formInput: FormType
  handleChange: (item: FormType, event: HandleChangeEvent) => void
  options?: SelectOptions[]
}

const Select = ({ formInput, options, handleChange }: Props) => {
  const defaultOptions = [{ value: 'options was not provided', label: 'options was not provided' }]

  function findOptions() {
    const selectOptions = options?.find((el) => {
      return el.name === formInput.name
    })

    return selectOptions ? selectOptions.options : defaultOptions
  }

  function handleSelectChange(item: FormType, selectedOption: any) {
    handleChange(item, { target: { value: selectedOption.value } })
  }

  const customStyles = {
    menuList: (base: CSSProperties) => ({
      ...base,
      '::-webkit-scrollbar': {
        width: '10px',
      },
      '::-webkit-scrollbar-track': {
        background: '#f1f1f1',
      },
      '::-webkit-scrollbar-thumb': {
        background: '#888',
      },
      '::-webkit-scrollbar-thumb:hover': {
        background: '#888',
      },
      paddingBottom: '0',
    }),

    menu: (provided: CSSProperties) => ({
      ...provided,
      border: '2px solid #FF8C1A',
      borderRadius: '0 0 0.5em 0.5em',
      padding: '0',
      margin: '0',
      position: 'relative',
      zIndex: '200',
      top: '-0.5em',
      maxWidth: `${formInput.width ? formInput.width + 0.25 : 10.2}em`,
      '@media only screen and (max-width: 950px)': {
        maxWidth: `${formInput.mobileWidth ? formInput.mobileWidth + 0.25 : 10}em`,
      },
    }),

    option: (provided: CSSProperties, state: any) => {
      return {
        borderTop: '2px solid #FF8C1A',
        color: '#FF8C1A',
        fontFamily: 'Roboto Condensed',
        fontSize: '14px',
        fontWeight: 'bold',
        height: '2.65em',
        display: 'flex',
        alignItems: 'center',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        paddingLeft: '0.875em',
        paddingRight: '0.875em',
        paddingBottom: '0',
        margin: '0',
        cursor: 'pointer',
        backgroundImage: state.isSelected ? "url('/img/select-check.svg')" : '',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '98.5% center',
        maxWidth: `${formInput.width ? formInput.width : 10}em`,
        '&:hover': {
          backgroundColor: '#FF8C1A',
          color: 'white',
          backgroundImage: state.isSelected ? "url('/img/select-check-white.svg')" : '',
        },
        '@media only screen and (max-width: 950px)': {
          maxWidth: `${formInput.mobileWidth ? formInput.mobileWidth : 10}em`,
        },
      }
    },

    control: (provided: CSSProperties, state: any) => ({
      border: '2px solid #FF8C1A',
      backgroundColor: state.selectProps.menuIsOpen ? '#FF8C1A' : 'white',
      display: 'flex',
      borderRadius: state.selectProps.menuIsOpen ? '0.5em 0.5em 0 0' : '0.5em',
      cursor: 'pointer',
      position: 'relative',
      zIndex: '300',
      height: '2.65em',
      width: `${formInput.width ? formInput.width : 10}em`,
      '@media only screen and (max-width: 950px)': {
        maxWidth: `${formInput.mobileWidth ? formInput.mobileWidth : 10}em`,
      },
    }),

    indicatorSeparator: () => ({
      display: 'none',
    }),

    dropdownIndicator: (provided: CSSProperties, state: any) => ({
      ...provided,
      color: state.selectProps.menuIsOpen ? 'white' : '#FF8C1A',
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
      transition: '0',
      '&:hover': {
        color: state.selectProps.menuIsOpen ? 'white' : '#FF8C1A',
      },
    }),

    singleValue: (provided: CSSProperties, state: any) => ({
      color: state.selectProps.menuIsOpen ? 'white' : '#FF8C1A',
      fontFamily: 'Roboto Condensed',
      fontSize: '14px',
      fontWeight: 'bold',
      position: 'absolute',
    }),
  }

  return (
    <ReactSelect
      options={findOptions()}
      isSearchable={false}
      //@ts-ignore
      styles={customStyles}
      defaultValue={findOptions()[0]}
      onChange={(selectedOption) => handleSelectChange(formInput, selectedOption)}
    />
  )
}

export default Select
