import { SortingValues } from './sorting'

export interface HeaderItem {
  textKey: SortingValues | '#'
  width?: number
  disabledSorting?: boolean
}

export const NO_SCROLLABLE = 'NO_SCROLLABLE'
export const SCROLLABLE = 'SCROLLABLE'

export interface NoScrollableItems {
  items: HeaderItem[]
  width?: number
  textKey: typeof NO_SCROLLABLE
}

export interface ScrollableItems {
  items: HeaderItem[]
  width?: number
  textKey: typeof SCROLLABLE
}

export type HeaderData = HeaderItem | NoScrollableItems | ScrollableItems

export const headerData: HeaderData[] = [
  {
    textKey: NO_SCROLLABLE,
    items: [
      {
        textKey: '#',
        width: 3,
        disabledSorting: true,
      },
      {
        textKey: SortingValues.USER,
        width: 5,
      },
    ],
  },
  {
    textKey: SCROLLABLE,
    items: [
      {
        textKey: SortingValues.ELO,
        width: 5,
      },
      {
        textKey: SortingValues.GAMES_COUNT,
        width: 7,
      },
      {
        textKey: SortingValues.TIME,
        width: 5,
      },
      {
        textKey: SortingValues.TIME_PER_GAME,
        width: 7,
      },
      {
        textKey: SortingValues.WON_GAME,
        width: 4,
      },
      {
        textKey: SortingValues.FLEXIBILITY,
      },
      {
        textKey: SortingValues.WON_GAME_ATTACKER,
      },
      {
        textKey: SortingValues.WON_GAME_DEFENDER,
      },
      {
        textKey: SortingValues.ACTION,
        width: 5,
      },
    ],
  },
]
