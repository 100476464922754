import { css } from 'styled-components'

interface ButtonStylesProps {
  loading?: boolean
  redColor?: boolean
}

export default css<ButtonStylesProps>`
  color: white;
  font-family: 'Roboto Condensed';
  font-size: 1.0625rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background-color: #52a00b;
  border: 1px solid #52a00b;
  cursor: pointer;
  outline: none;

  &:hover {
    background-color: #40770d;
    border-color: #40770d;
  }

  ${({ redColor }) =>
    redColor &&
    css`
      background-color: #ff311a;
      border-color: #ff311a;

      &:hover {
        background-color: #c62412;
        border-color: #c62412;
      }
    `}

  ${({ loading }) =>
    loading &&
    css`
      color: transparent;
      border-color: #2a2a2a;
      background-color: #2a2a2a;
      background-image: url(/img/loader.gif);
      background-size: 1.5em 1.5em;
      background-position: center;
      background-repeat: no-repeat;
      pointer-events: none;

      &:hover {
        background-color: #2a2a2a;
      }
    `}
`
