import styled from 'styled-components'

import { NormalizedUser } from '../../slices/userSlice'
import { itemStyles } from '../screens/statistics/Table/itemStyles'

interface Props {
  user: NormalizedUser
}

const User = ({ user: userInfo }: Props) => {
  if (!userInfo) {
    return <></>
  }

  return (
    <UserContainer>
      <Avatar img={userInfo.avatar ? userInfo.avatar.S : '/img/no-avatar.svg'} />
      <UserNameContainer>
        <Name>{userInfo.firstName}&nbsp;</Name>
        <Surname>{userInfo.lastName}</Surname>
      </UserNameContainer>
    </UserContainer>
  )
}

export default User

interface AvatarProps {
  img: string
}

const Avatar = styled.div<AvatarProps>`
  width: 2.5em;
  height: 2.5em;
  min-height: 2.5em;
  min-width: 2.5em;
  border-radius: 50%;
  background-image: url('${({ img }) => img}');
  background-size: cover;
  background-position: center;
  margin-right: 1em;
`

const UserContainer = styled.div`
  ${itemStyles}
  display: flex;
  align-items: center;
`

const UserNameContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column;
    max-width: 6em;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const Name = styled.div`
  ${itemStyles}
  white-space: nowrap;

  @media (max-width: 768px) {
    padding: 0;
  }
`

const Surname = styled(Name)``
