import { useState } from 'react'
import styled from 'styled-components'
import MenuLinkContainer from './MenuLinkContainer'
import PopUp from '../../screens/User/Edit/PopUp'

interface Props {
  username: string
  avatar: string
}

const User = ({ username, avatar }: Props) => {
  const [active, setActive] = useState(false)

  return (
    <>
      <PopUp active={active} setActive={setActive} onPopUpClose={() => setActive(false)} />
      <MenuLinkContainer isActive={active} onClick={() => setActive(true)}>
        <Avatar imgUrl={avatar ? avatar : './img/no-avatar.svg'} />
        <span>{username}</span>
      </MenuLinkContainer>
    </>
  )
}

export default User

interface AvatarProps {
  imgUrl: string
}

const Avatar = styled.div<AvatarProps>`
  margin: 0 0.88888888888em;
  width: 1.33333333333em;
  min-width: 1.33333333333em;
  height: 1.33333333333em;
  border-radius: 50%;
  background-image: url('${({ imgUrl }) => imgUrl}');
  background-position: center;
  background-size: cover;
`
