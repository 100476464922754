import styled from 'styled-components'

enum Paths {
  Logout = 'M16 0a2 2 0 012 2v4h-1.2V2a.8.8 0 00-.8-.8H2a.8.8 0 00-.8.8v20a.8.8 0 00.8.8h14a.8.8 0 00.8-.8v-4H18v4a2 2 0 01-2 2H2a2 2 0 01-2-2V2a2 2 0 012-2h14zm3.749 8l4.242 4.243-4.242 4.242-.849-.848 2.746-2.747H11v-1.2h10.742L18.9 8.849 19.749 8z',
  Users = 'M12 13.5c7.029 0 9 2.936 9 5.786V21H3v-1.714c0-2.85 1.971-5.786 9-5.786zm0-1.5a4.499 4.499 0 01-4.5-4.5C7.5 5.014 9.514 3 12 3s4.5 2.014 4.5 4.5S14.486 12 12 12z',
  Statistics = 'M.462 21A.462.462 0 010 20.538v-.02c0-.256.207-.462.462-.462l1.384-.001v-7.576c0-.51.413-.923.923-.923h1.846c.51 0 .923.413.923.923v7.576h1.846V7.756c0-.51.414-.923.924-.923h1.846c.51 0 .923.414.923.923l-.001 12.299h1.847V10.59c0-.51.413-.923.923-.923h1.846c.51 0 .923.413.923.923v9.465h1.846V4.923c0-.51.414-.923.924-.923h1.846c.51 0 .923.413.923.923l-.001 15.132h1.385c.255 0 .462.207.462.462v.021a.462.462 0 01-.462.462H.462z',
  Management = 'M22.512 10.086l-2.524-.62a8.33 8.33 0 00-.582-1.387l1.175-1.958a.645.645 0 00-.097-.787L18.661 3.51a.643.643 0 00-.787-.097l-1.961 1.175a8.303 8.303 0 00-1.378-.577l-.62-2.524A.644.644 0 0013.288 1h-2.578a.644.644 0 00-.625.488s-.43 1.762-.62 2.523c-.507.16-.996.37-1.463.623L5.97 3.414a.646.646 0 00-.787.097L3.36 5.334a.645.645 0 00-.097.787l1.258 2.1c-.203.4-.373.816-.508 1.242l-2.524.623A.644.644 0 001 10.71v2.578c0 .296.201.554.488.625l2.525.62c.153.486.351.957.593 1.41L3.48 17.82a.645.645 0 00.097.787L5.4 20.43a.647.647 0 00.787.097l1.88-1.127c.448.239.916.436 1.399.589l.62 2.523c.071.287.33.488.625.488h2.578a.644.644 0 00.625-.488l.62-2.523c.497-.158.975-.361 1.43-.608l1.91 1.146a.645.645 0 00.787-.097l1.823-1.823a.645.645 0 00.097-.787l-1.159-1.93c.229-.434.418-.888.566-1.355l2.524-.62a.644.644 0 00.488-.626v-2.578a.644.644 0 00-.488-.625zM12 16.512A4.517 4.517 0 017.488 12 4.517 4.517 0 0112 7.488 4.517 4.517 0 0116.512 12 4.517 4.517 0 0112 16.512z',
}

export enum PathKeys {
  Logout = 'Logout',
  Users = 'Users',
  Statistics = 'Statistics',
  Management = 'Management',
}

interface Props {
  path: PathKeys
}

const Icon = (props: Props) => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <Path fill="#52A00B" d={Paths[props.path]} />
  </Svg>
)

export default Icon

const Svg = styled.svg`
  width: 1.33333333333em;
  min-width: 1.33333333333em;
  height: 1.33333333333em;
  margin: 0 0.88888888888em;
`

export const Path = styled.path`
  transition: fill 0.2s ease-in-out;
`
