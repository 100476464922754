import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import queryString from 'query-string'
import { useLocation } from 'react-router'

import reduceInputData from '../functions/reduceInputData'
import { fetchSharingStatistics, fetchStatistics } from '../slices/statisticsSlice'
import { StateType } from '../Input/InputStateType'

export function useStatisticsUpdate(inputValue: StateType, sharing?: boolean) {
  const dispatch = useDispatch()
  const location = useLocation()
  const splitted = location.pathname.split('/')
  const urlKey = splitted[2]

  useEffect(() => {
    const interval = setInterval(() => {
      const reduced = reduceInputData(inputValue)
      const queryStringObj = queryString.parse(location.search)
      const sortBy = queryStringObj['sortBy'] as string
      const order = queryStringObj['order'] as string

      if (reduced.gameTypes) {
        const gameInterval = reduced.gameInterval ? reduced.gameInterval : ''

        if (sharing) {
          dispatch(
            fetchSharingStatistics({
              urlKey,
              sortBy,
              order,
            })
          )
        } else {
          dispatch(
            fetchStatistics({
              gameTypeId: reduced.gameTypes,
              interval: gameInterval,
              sortBy,
              order,
            })
          )
        }
      }
    }, 50000)

    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue, location.search])
}
