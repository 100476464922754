import styled from 'styled-components'

const RadioIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <path
      fill="#bebebe"
      d="M8 0c4.416 0 8 3.584 8 8s-3.584 8-8 8-8-3.584-8-8 3.584-8 8-8Zm0 1.6A6.398 6.398 0 0 0 1.6 8c0 3.536 2.864 6.4 6.4 6.4 3.536 0 6.4-2.864 6.4-6.4 0-3.536-2.864-6.4-6.4-6.4Z"
    />
    <circle fill="#52A00B" cx="8" cy="8" r="4" />
  </Svg>
)

export default RadioIcon

const Svg = styled.svg`
  grid-area: Icon;
  width: 1em;
  height: 1em;
  margin-right: 0.625em;

  circle {
    opacity: 0;
  }

  circle,
  path {
    transition: all 0.2s ease-in-out;
  }
`
