import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import styled from 'styled-components'

import reduceInputData from '../../../functions/reduceInputData'
import { StateType } from '../../../Input/InputStateType'
import { SelectOptions } from '../../../Input/Select'
import MainTitle from '../../styled/MainTitle'
import FilterButtons from './FilterButtons'
import Table from './Table/'
import { LoadingStates } from '../../../constants/sliceTypes/loadingStates'
import ShareButton from './ShareButton'
import SharePopUp from './SharePopUp'
import { useMediaQuery } from 'react-responsive'
import { StatisticsState } from '../../../constants/sliceTypes/statisticsSlice'
import { GameTypesState } from '../../../constants/sliceTypes/gameTypesSlice'
import Loader from '../../layout/Loader'
import { useSelector } from 'react-redux'
import { getStatistics } from '../../../selectorFunctions'

interface Props {
  statisticsData: StatisticsState
  gameTypes: GameTypesState
  changingEnabled: boolean
  statisticsSharing: boolean
  inputValue: StateType
  setInputValue: Dispatch<SetStateAction<StateType>>
  selectedButtonValues?: string
  defaultButtonValues?: object
  leagueName?: string
}

const Main = ({
  gameTypes,
  inputValue,
  setInputValue,
  selectedButtonValues,
  statisticsData,
  statisticsSharing,
  changingEnabled,
  defaultButtonValues,
  leagueName,
}: Props) => {
  const [options, setOptions] = useState<SelectOptions[]>()
  const [sharePopUpVisible, setSharePopUpVisible] = useState(false)
  const { state: statisticsState, params } = useSelector(getStatistics())

  const smallDevice = useMediaQuery({
    query: '(max-width: 768px)',
  })

  useEffect(() => {
    if (gameTypes && gameTypes.state === LoadingStates.Succeeded) {
      const gameTypesOptions = gameTypes.data.map((el) => {
        return { value: el._id, label: el.name }
      })

      const gameIntervalOptions = [
        {
          value: 'day',
          label: 'den',
        },
        {
          value: 'week',
          label: '7 dnů',
        },
        {
          value: 'days30',
          label: '30 dní',
        },
        {
          value: '',
          label: 'neomezeně',
        },
      ]

      const allOptions = [
        { name: 'gameTypes', options: gameTypesOptions },
        { name: 'gameInterval', options: gameIntervalOptions },
      ]

      if (selectedButtonValues || defaultButtonValues) {
        const parsedButtonValues = defaultButtonValues
          ? defaultButtonValues
          : JSON.parse(selectedButtonValues!)

        const findSelectedInterval = gameIntervalOptions.find(
          (el) => el.value === parsedButtonValues.interval
        )
        const findSelectedGameType = gameTypesOptions.find(
          (el) => el.value === parsedButtonValues.gameType
        )

        if (findSelectedGameType && findSelectedInterval) {
          const intervalIndex = gameIntervalOptions.indexOf(findSelectedInterval)
          const gameTypeIndex = gameTypesOptions.indexOf(findSelectedGameType)

          gameIntervalOptions.splice(intervalIndex, 1)
          gameTypesOptions.splice(gameTypeIndex, 1)

          gameIntervalOptions.unshift(findSelectedInterval)
          gameTypesOptions.unshift(findSelectedGameType)
        }
      }

      setInputValue({
        gameTypes: { value: gameTypesOptions[0].value, isInvalid: false },
        gameInterval: { value: gameIntervalOptions[0].value, isInvalid: false },
      })
      setOptions(allOptions)
    }
  }, [gameTypes]) // eslint-disable-line

  if (!statisticsData) return null

  const share = (
    <Share onClick={() => setSharePopUpVisible(true)}>
      <ShareButton />
    </Share>
  )

  return (
    <>
      {statisticsState === LoadingStates.Pending &&
        reduceInputData(inputValue).gameTypes !== params?.gameTypeId &&
        reduceInputData(inputValue).gameInterval !== params?.interval && <Loader />}
      <SharePopUp
        gameTypeId={reduceInputData(inputValue).gameTypes}
        interval={reduceInputData(inputValue).gameInterval}
        visible={sharePopUpVisible}
        setVisible={setSharePopUpVisible}
      />
      <TopSection>
        <MainTitleSection statisticsSharing={statisticsSharing}>
          <MainTitle>Statistiky</MainTitle>
          <LeagueName statisticsSharing={statisticsSharing}>{leagueName}</LeagueName>
          {smallDevice && !statisticsSharing && share}
        </MainTitleSection>
        <Left withoutShareButton={statisticsSharing}>
          {options && changingEnabled && (
            <FilterButtons
              inputValue={inputValue}
              setInputValue={setInputValue}
              options={options}
            />
          )}
          {!smallDevice && !statisticsSharing && share}
        </Left>
      </TopSection>
      <Table
        selectedGameTypeID={reduceInputData(inputValue).gameTypes}
        interval={reduceInputData(inputValue).gameInterval}
        statistics={statisticsData.data}
      />
    </>
  )
}

export default Main

const TopSection = styled.section`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;

  @media (max-width: 950px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const Share = styled.div``

interface LeftProps {
  withoutShareButton: boolean
}

const Left = styled.section<LeftProps>`
  width: 90%;
  margin-right: 3em;
  height: 3em;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;

  @media (max-width: 950px) {
    width: ${({ withoutShareButton }) => (withoutShareButton ? 18 : 21)}em;
    justify-content: flex-end;
    margin: -2em 0 1.5em 3em;
  }

  @media (max-width: 768px) {
    width: 18em;
    align-items: center;
    height: 3.5em;
    padding-left: 1em;
    margin: 0 1em 1em 0;
  }
`
interface SharingProps {
  statisticsSharing: boolean
}

const MainTitleSection = styled.div<SharingProps>`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 2em;

  @media (max-width: 768px) {
    align-items: flex-start;
    width: 18em;
    justify-content: space-between;
    margin-bottom: ${({ statisticsSharing }) => (statisticsSharing ? 1 : -2)}em;
  }
`

const LeagueName = styled(MainTitle)<SharingProps>`
  line-height: 0;
  font-size: 1.2em;
  width: 7em;
  margin-top: -1.8em;

  @media (max-width: 950px) {
    margin-bottom: 1em;
  }

  @media (max-width: 768px) {
    margin-bottom: 0;
    margin-top: -1em;
    font-size: 1.2em;
    margin-left: 0.8em;
  }
`
