import { useEffect, useRef, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import styled from 'styled-components'

interface Props {
  value: string
}

const CopyInput = ({ value }: Props) => {
  const [copied, setCopied] = useState(false)
  const timer = useRef<number>()

  useEffect(() => {
    return () => clearInterval(timer.current)
  }, [])

  function handleCopy() {
    setCopied(true)
    clearInterval(timer.current)
    timer.current = window.setTimeout(() => {
      setCopied(false)
    }, 1000)
  }

  return (
    <>
      <CopyContainer>
        <GenerateLinkInput type="text" value={value} disabled />
        <CopyToClipboard text={value} onCopy={handleCopy}>
          <CopyToClipboardButton imageUrl="./img/copy-to-clipboard-icon.svg" />
        </CopyToClipboard>
      </CopyContainer>
      {copied && (
        <CopiedMessageContainer>
          <CopiedMessage>Odkaz zkopírován do schránky</CopiedMessage>
        </CopiedMessageContainer>
      )}
    </>
  )
}

export default CopyInput

const GenerateLinkInput = styled.input`
  margin-top: 2em;
  width: 80%;
  height: 3.5em;
  border: none;
  background-color: #f5f5f5;
  border-radius: 0.375em;
  text-align: center;
  color: #2a2a2a;
  font-family: Roboto Condensed;
  font-size: 0.875em;
  font-weight: bold;
  line-height: 1em;
`

interface CopyToClipboardButtonProps {
  imageUrl: string
}

const CopyContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const CopyToClipboardButton = styled.button<CopyToClipboardButtonProps>`
  background-image: url('${({ imageUrl }) => imageUrl}');
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  width: 3.1rem;
  height: 3.1rem;
  background-color: #ff8c1a;
  border-radius: 0.5em;
  outline: none;
  cursor: pointer;
  position: relative;
  top: 1em;
  left: -0.3em;

  &:hover {
    background-color: #c86809;
  }
`

const CopiedMessageContainer = styled.div`
  width: 73%;
  display: flex;
  justify-content: flex-end;
  position: relative;
  top: -2em;
`

const CopiedMessage = styled.div`
  height: 1.2em;
  border-radius: 0.125em 0 0 0.125em;
  color: white;
  background-color: rgba(255, 140, 26, 0.86);
  font-family: Roboto Condensed;
  font-size: 0.625em;
  line-height: 0.6875em;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0.5em;
  padding-right: 0.5em;
`
