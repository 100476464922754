import { Route, Switch, BrowserRouter as Router } from 'react-router-dom'

import Login from './components/screens/Login'
import Auth0Callback from './components/screens/Login/Auth0Callback'
import Statistics from './components/screens/statistics/'
import SharedStatistics from './components/screens/statistics/Share/'
import PopUp from './components/screens/User/Registration/PopUp'
import UserMenuRoute from './Routes/UserMenuRoute'
import Users from './components/screens/Users/Main'
import Management from './components/screens/Management'
import { PathKeys } from './components/layout/UserMenu/Icon'

export const App = () => {
  return (
    <Router>
      <Switch>
        <Route component={Login} path="/" exact />
        <Route component={Auth0Callback} path="/auth0-callback" />
        <UserMenuRoute Component={Statistics} path="/statistics" activePath={PathKeys.Statistics} />
        <UserMenuRoute Component={Users} path="/users" activePath={PathKeys.Users} />
        <UserMenuRoute Component={Management} path="/management" activePath={PathKeys.Management} />
        <Route component={SharedStatistics} path="/s/" />
      </Switch>
      <PopUp />
    </Router>
  )
}
