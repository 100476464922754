export enum LoadingStates {
  Pending = 'Pending',
  Succeeded = 'Succeeded',
  Failed = 'Failed',
  Empty = '',
}

export type LoadingStatesType =
  | typeof LoadingStates.Pending
  | typeof LoadingStates.Succeeded
  | typeof LoadingStates.Failed
  | typeof LoadingStates.Empty
