import { Dispatch, SetStateAction } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { getLoggedUser, getUserById } from '../../../../selectorFunctions'
import { leaveAccount } from '../../../../slices/userSlice'
import ConfirmActionPopUp from '../../../layout/ConfirmActionPopUp'

interface Props {
  userId: string
  hovered: boolean
  setHovered: Dispatch<SetStateAction<boolean>>
}

const Actions = ({ userId, hovered, setHovered }: Props) => {
  const loggedUser = useSelector(getLoggedUser())
  const user = useSelector(getUserById(userId))
  const dispatch = useDispatch()
  const [visible, setVisible] = useState(false)
  const messageText = `Opravdu si přejete odebrat uživatele ${user.firstName} ${user.lastName} z ligy? Všechny údaje včetně statistik, budou nenávratně ztraceny!`
  const myUser = loggedUser._id === userId

  function handleDelete() {
    dispatch(leaveAccount(userId))
    setVisible(false)
  }

  function handleClose() {
    setVisible(false)
    setHovered(false)
  }

  return (
    <>
      <ConfirmActionPopUp
        handleClose={handleClose}
        abortButtonText="Zrušit"
        confirmButtonText="Smazat"
        messageText={messageText}
        handleConfirm={handleDelete}
        visible={visible}
      />
      {hovered && !myUser && (
        <Container>
          <Delete onClick={() => setVisible(true)} />
        </Container>
      )}
    </>
  )
}

export default Actions

const Container = styled.div`
  width: 4em;
  display: flex;
  justify-content: flex-start;
`

const Delete = styled.div`
  background-image: url('/img/trash-icon.svg');
  background-position: center;
  background-repeat: no-repeat;
  height: 1.5em;
  width: 1.5em;
  cursor: pointer;
`
