import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { StatisticsTypeUserString } from '../../../../constants/sliceTypes/statisticsSlice'
import { getLoggedUser } from '../../../../selectorFunctions'
import EmptyStatisticsState from './EmptyStatisticsState'
import FixedItem from './FixedItem'
import FixedTop from './FixedTop'
import Header from './Header'
import Item from './Item'

interface Props {
  statistics: StatisticsTypeUserString[]
  interval?: string
  selectedGameTypeID: string
}

const Table = ({ statistics, interval, selectedGameTypeID: selectedGameTypeName }: Props) => {
  const loggedUser = useSelector(getLoggedUser())

  function renderEmptyState() {
    if (loggedUser && loggedUser.accounts && loggedUser.accounts.length === 0)
      return <EmptyStatisticsState text="Zatím nejsi připojen do žádné ligy!" />
    else if (statistics.length === 0)
      return <EmptyStatisticsState text="Žádné statistiky nejsou k dispozici" />
  }

  return (
    <VerticalContainer>
      <Container>
        <Fixed>
          <FixedTop interval={interval} />
          {statistics.length > 0 &&
            statistics.map((item, index) => (
              <FixedItem interval={interval} key={index} item={item} index={index} />
            ))}
        </Fixed>
        <Scrollable statisticsExist={statistics.length !== 0}>
          <TableComponent>
            <Header interval={interval} />
            <TableBody>
              {statistics.length > 0 &&
                statistics.map((item, index) => (
                  <Item
                    selectedGameTypeID={selectedGameTypeName}
                    interval={interval}
                    key={index}
                    item={item}
                    index={index}
                  />
                ))}
            </TableBody>
          </TableComponent>
        </Scrollable>
      </Container>
      {renderEmptyState()}
    </VerticalContainer>
  )
}

export default Table

const VerticalContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`

const Fixed = styled.div`
  position: sticky;
  width: 18em;
  padding-right: 2.4em;
  margin-left: -13em;
  z-index: 100;
  left: 0;

  @media (max-width: 768px) {
    padding-right: 0;
  }
`

interface ScrollableProps {
  statisticsExist: boolean
}

const TableBody = styled.tbody``

const Scrollable = styled.div<ScrollableProps>`
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow-x: ${({ statisticsExist }) => (statisticsExist ? 'auto' : 'hidden')};
  overflow-y: hidden;
  white-space: nowrap;
  margin-right: -4em;
`

const TableComponent = styled.table`
  width: 100%;
  height: 100%;
  border-collapse: collapse;
`
