import { Dispatch, SetStateAction, useEffect } from 'react'
import styled from 'styled-components'
import PopUp from '../../PopUp/Main'
import QRCode from 'qrcode.react'
import CopyInput from '../../layout/CopyInput'
import { useDispatch, useSelector } from 'react-redux'
import { getActiveAccountId, getUserState } from '../../../selectorFunctions'
import { shortInviteUserLink } from '../../../slices/userSlice'

interface Props {
  visible: boolean
  setVisible: Dispatch<SetStateAction<boolean>>
}

const AddUserPopUp = ({ visible, setVisible }: Props) => {
  const token = localStorage.getItem('token')
  const activeAccountId = useSelector(getActiveAccountId())
  const { shortInviteLink } = useSelector(getUserState())
  const dispatch = useDispatch()
  const prefix = 'https://link.foosapp.eu'
  const longInviteLink = `https://app.foosapp.eu/join?inviteToken=${token}&type=account&accountId=${activeAccountId}`

  useEffect(() => {
    dispatch(
      shortInviteUserLink({
        longDynamicLink: longInviteLink,
        domainUriPrefix: prefix,
      })
    )
  }, []) //eslint-disable-line

  return (
    <PopUp
      visible={visible}
      setVisible={setVisible}
      titleText="PŘIDAT HRÁČE"
      size={{ width: '30em', height: '35em' }}
    >
      <Container>
        <QRCode value={shortInviteLink || ''} size={250} />
        <Margin />
        <CopyInput value={shortInviteLink || ''} />
      </Container>
    </PopUp>
  )
}

export default AddUserPopUp

const Container = styled.div`
  width: 100%;
  margin-top: 4em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const Margin = styled.div`
  margin-top: 2em;
`
