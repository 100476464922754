import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { inset } from '../../../../utils/styles'

interface Props {
  disabled?: boolean
}

const Close = (props: Props) => (
  <Button tabIndex={props.disabled ? -1 : 0} to="/management">
    <svg viewBox="0 0 14 14">
      <path
        fill="#FF8C1A"
        d="M12.923 0L14 1.077 8.076 7 14 12.923 12.923 14 7 8.076 1.077 14 0 12.923 5.923 7 0 1.077 1.077 0 7 5.924 12.923 0z"
      />
    </svg>
  </Button>
)

export default Close

export const Button = styled(Link)`
  ${inset('0.5625em 0.5625em auto auto')}
  position: absolute;
  font-size: 1em;
  width: 1.75em;
  height: 1.75em;
  border: none;
  display: grid;
  place-content: center;

  > svg {
    width: 0.875em;
    height: 0.875em;
  }

  path {
    transition: fill 0.2s ease-in-out;
  }

  :hover path {
    fill: #c86809;
  }

  &[tabindex='-1'] {
    pointer-events: none;

    path {
      fill: #e6e6e6;
    }
  }
`
