import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { getAccountById } from '../../../../selectorFunctions'
import MenuLinkContainer from '../MenuLinkContainer'
import { Icon, LogoContainer } from './styled'

interface Props {
  isActive: boolean
  accountId: string
  showAll: boolean
  onClick: () => void
}

const ActiveLeague = (props: Props) => {
  const account = useSelector(getAccountById(props.accountId))

  return (
    <StyledMenuLinkContainer
      isActive={props.isActive}
      hoverBackground="#333"
      onClick={(event) => {
        event.nativeEvent.stopImmediatePropagation()
        props.onClick()
      }}
    >
      <LogoContainer imageUrl={account.avatar?.S || './img/no-avatar.svg'} />
      {account.name}
      <Icon $rotate={props.showAll} src="./img/right-down.svg" alt="" />
    </StyledMenuLinkContainer>
  )
}

export default ActiveLeague

const StyledMenuLinkContainer = styled(MenuLinkContainer)`
  border-bottom: 1px solid #ff8b1a;
`
