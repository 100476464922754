export default function convertSecToTime(seconds: number | string) {
  if (typeof seconds === 'string') {
    return 'N/A'
  }
  let dateString = ''
  let secMod = seconds % 60
  let minutes = seconds / 60
  let minMod = minutes % 60
  let hours = minutes / 60

  if (Math.floor(hours) > 0) {
    dateString += `${Math.floor(hours)}h `
  }

  if (Math.floor(minMod) > 0) {
    dateString += `${Math.floor(minMod)}m `
  }

  if (Math.floor(secMod) > 0) {
    dateString += `${Math.floor(secMod)}s`
  }

  return dateString ? dateString : 'N/A'
}
