import styled from 'styled-components'

import UserRow from './UserRow'

interface Props {
  userIds: string[]
}

const UsersSection = ({ userIds }: Props) => (
  <Container>
    {userIds.map((id) => (
      <UserRow id={id} key={id} />
    ))}
  </Container>
)

export default UsersSection

const Container = styled.div`
  border-top: 1px solid rgb(229, 230, 230);
`
