import { css } from 'styled-components'

export interface ContainerProps {
  bgColor: string
  interval?: string
}

export const containerStyles = css<ContainerProps>`
  width: 100%;
  background-color: ${({ bgColor }) => bgColor};
  height: 4em;
`
