import { useState } from 'react'
import styled from 'styled-components'

import BaseStep from './BaseStep'
import CheckboxInput from './CheckboxInput'
import { isValid, useTextInput, useValuesOnLeave, Values } from './inputHelpers'
import { Continue } from './styled'
import TextInput from './TextInput'

interface Props {
  values: Values
  onContinue: () => void
  onBack: () => void
  onValues: (values: Values) => void
}

const Step2 = (props: Props) => {
  const [forCompany, setForCompany] = useState(
    !!(props.values.companyName && props.values.registrationId && props.values.vatId)
  )

  const addressData = {
    street: useTextInput(props.values.street),
    city: useTextInput(props.values.city),
    zip: useTextInput(props.values.zip),
    country: useTextInput(props.values.country),
  }

  const companyData = {
    companyName: useTextInput(props.values.companyName),
    registrationId: useTextInput(props.values.registrationId),
    vatId: useTextInput(props.values.vatId),
  }

  const data = forCompany ? { ...addressData, ...companyData } : addressData

  useValuesOnLeave(data, props.onValues)

  return (
    <BaseStep title="NADPIS" onBack={props.onBack}>
      <Inner>
        <CheckboxInput value={forCompany} onChange={setForCompany} label="Nakupuji na firmu" />

        <Rows>
          <Row>
            <TextInput {...data.street} label="Ulice a číslo popisné" />
            <TextInput {...data.city} label="Město" />
            <TextInput {...data.zip} label="PSČ" />
            <TextInput {...data.country} label="Země" />
          </Row>

          <Row>
            <TextInput {...companyData.companyName} label="Název firmy" isEnabled={forCompany} />
            <TextInput {...companyData.registrationId} label="IČ" isEnabled={forCompany} />
            <TextInput {...companyData.vatId} label="DIČ" isEnabled={forCompany} />
          </Row>
        </Rows>

        <Continue onClick={isValid(data) ? props.onContinue : undefined} />
      </Inner>
    </BaseStep>
  )
}

export default Step2

const Inner = styled.div`
  margin: 0 2.75em;
  width: 38.5em;

  @media (max-width: 44.375em) {
    margin: auto 5.75em;
    width: 17.5em;
  }

  @media (max-width: 31.25em) {
    margin: 0 3em;
    width: calc(100% - 6em);
  }
`

const Rows = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 3.5em;

  @media (max-width: 44.375em) {
    grid-template-columns: 1fr;
  }
`

const Row = styled.div``
