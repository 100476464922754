import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getActiveAccountId, getActiveAccountUserIds, getGameTypes } from '../selectorFunctions'
import { fetchGameTypes } from '../slices/gameTypesSlice'
import { fetchUsers } from '../slices/userSlice'

export function useGameTypes() {
  const dispatch = useDispatch()

  const accountId = useSelector(getActiveAccountId())
  const gameType = useSelector(getGameTypes())

  React.useEffect(() => {
    if (accountId) dispatch(fetchGameTypes(accountId))
  }, [accountId, dispatch])

  return gameType
}

export function useUserIds() {
  const dispatch = useDispatch()

  const accountId = useSelector(getActiveAccountId())
  const userIds = useSelector(getActiveAccountUserIds())

  React.useEffect(() => {
    if (accountId) dispatch(fetchUsers(accountId))
  }, [accountId, dispatch])

  return userIds
}

export function useOnClickOutside(
  ref: React.RefObject<HTMLElement>,
  callback: () => void,
  isEnabled: boolean
) {
  React.useEffect(() => {
    if (!isEnabled) return

    function onClick(event: MouseEvent) {
      if (event.target instanceof Element && ref.current && !ref.current.contains(event.target))
        callback()
    }

    document.addEventListener('click', onClick)

    return () => {
      document.removeEventListener('click', onClick)
    }
    // eslint-disable-next-line
  }, [isEnabled])
}

export function useToRef<T>(toRef: T) {
  const ref = React.useRef<T>()
  ref.current = toRef

  return ref
}

export function useState<T>(initialState?: T): [typeof state, typeof setState, typeof stateRef] {
  const [state, setState] = React.useState(initialState as T)
  const stateRef = useToRef(state)

  return [state, setState, stateRef]
}
