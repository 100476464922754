import { RootState } from '../store/configureStore'

export function getAccountById(accountId: string) {
  return (state: RootState) => state.accounts.byId[accountId]
}

export function getActiveAccountId() {
  return (state: RootState) => state.accounts.activeAccountId
}

export function getActiveAccount() {
  return (state: RootState) => {
    const id = getActiveAccountId()(state)

    if (id) return state.accounts.byId[id]
  }
}

export function getActiveAccountName() {
  return (state: RootState) => {
    const account = getActiveAccount()(state)

    if (account) return account.name
  }
}

export function getActiveAccountUserIds() {
  return (state: RootState) => {
    const account = getActiveAccount()(state)

    if (account) return account.users
  }
}

export function getActiveUserAccountIds() {
  return (state: RootState) => {
    const user = getLoggedUser()(state)

    if (user) return user.accounts
  }
}

export function getUserState() {
  return (state: RootState) => state.user
}

export function getLoggedUser() {
  return (state: RootState) => state.user.byID[state.user.authUserID]
}

export function getRegistrationUser() {
  return (state: RootState) => state.user.byID['null']
}

export function getGameTypes() {
  return (state: RootState) => state.gameTypes
}

export function getGameTypeByID(gameTypeID: string) {
  return (state: RootState) => state.gameTypes.data.filter((el) => el._id === gameTypeID)
}

export function getUserById(userID: string) {
  return (state: RootState) => state.user.byID[userID]
}

export function getStatistics() {
  return (state: RootState) => state.statistics
}
