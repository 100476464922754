import { Dispatch, useEffect, useState, SetStateAction } from 'react'
import Main from '../Default/Main'
import { StateType } from '../../../../Input/InputStateType'
import { useDispatch, useSelector } from 'react-redux'
import reduceInputData from '../../../../functions/reduceInputData'
import isFormValid from '../../../../functions/IsFormValid'
import { userData } from '../../../../constants/forms/data'
import { useLocation } from 'react-router'
import { clearEditState, editUserSetting } from '../../../../slices/userSlice'
import { getLoggedUser, getUserState } from '../../../../selectorFunctions'
import { LoadingStates } from '../../../../constants/sliceTypes/loadingStates'

interface Props {
  active: boolean
  setActive: Dispatch<SetStateAction<boolean>>
  onPopUpClose: () => void
}

const PopUp = ({ active, setActive, onPopUpClose }: Props) => {
  const [image, setImage] = useState<any>()
  const [userInfo, setUserInfo] = useState({})
  const dispatch = useDispatch()
  const location = useLocation()
  const loggedUser = useSelector(getLoggedUser())
  const { editState } = useSelector(getUserState())

  function getUserInfo(data: StateType, image: File | undefined) {
    setUserInfo(data)
    setImage(image)
  }

  useEffect(() => {
    dispatch(clearEditState())
  }, []) //eslint-disable-line

  useEffect(() => {
    if (editState === LoadingStates.Succeeded) {
      setActive(false)
    }
  }, [editState]) //eslint-disable-line

  let prefilledData = {}
  if (loggedUser.firstName && loggedUser.lastName) {
    let isInvalid = false

    prefilledData = {
      name: { value: loggedUser.firstName, isInvalid },
      surname: { value: loggedUser.lastName, isInvalid },
    }
  }

  function handleSubmit() {
    let delAvatar = location.search.includes('del')

    if (isFormValid(userInfo, userData)) {
      const reduced = reduceInputData(userInfo)

      let dataToSent = {
        firstName: reduced.name,
        lastName: reduced.surname,
      }

      if (delAvatar) {
        dispatch(
          editUserSetting({ ...dataToSent, image, userID: loggedUser._id, deleteAvatar: true })
        )
      } else if (image) {
        dispatch(
          editUserSetting({ ...dataToSent, image, userID: loggedUser._id, deleteAvatar: false })
        )
      } else {
        dispatch(editUserSetting({ ...dataToSent, userID: loggedUser._id, deleteAvatar: false }))
      }
    }
  }

  return (
    <Main
      sendButtonText="Uložit"
      loading={editState === LoadingStates.Pending}
      titleText="ZMĚNIT ÚDAJE"
      onPopUpClose={onPopUpClose}
      active={active}
      withDelete={true}
      avatar={loggedUser.avatar ? loggedUser.avatar.L : './img/no-avatar.svg'}
      getUserInfo={getUserInfo}
      handleSubmit={handleSubmit}
      prefilledData={prefilledData}
    />
  )
}

export default PopUp
