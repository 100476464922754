import { useEffect } from 'react'
import { useHistory } from 'react-router'
import { useAuth0 } from '@auth0/auth0-react'
import { useDispatch, useSelector } from 'react-redux'
import useLocalStorage from 'react-use-localstorage'
import { authLogin } from '../../../slices/userSlice'
import { getUserState } from '../../../selectorFunctions'
import { LoadingStates } from '../../../constants/sliceTypes/loadingStates'

const Auth0Callback = () => {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0()
  const dispatch = useDispatch()
  const history = useHistory()
  const userState = useSelector(getUserState())
  const [token, setToken] = useLocalStorage('token', '') // eslint-disable-line

  function handleAuthorization() {
    getAccessTokenSilently()
      .then((r) => {
        setToken(r)
        dispatch(authLogin())
      })
      .catch((e) => {
        //Safari workaround
        if (e.error === 'login_required') {
          loginWithRedirect()
        }
        if (e.error === 'consent_required') {
          loginWithRedirect()
        }
        throw e
      })
  }

  useEffect(() => {
    handleAuthorization()
  }, []) // eslint-disable-line

  useEffect(() => {
    if (userState.loginState === LoadingStates.Succeeded) {
      return history.replace('/statistics')
    }
  }, [userState]) // eslint-disable-line

  return <></>
}

export default Auth0Callback
