import { useRef, useState, useEffect, ReactNode } from 'react'
import styled from 'styled-components'
import ResizeObserver from 'resize-observer-polyfill'
import { useDispatch, useSelector } from 'react-redux'
import { CSSTransition } from 'react-transition-group'

import { actions } from '../../../../slices/accountsSlice'
import { getActiveAccountId, getActiveUserAccountIds } from '../../../../selectorFunctions'
import ActiveLeague from './ActiveLeague'
import League from './League'
import { useOnClickOutside } from '../../../../hooks'
import { inset } from '../../../../utils/styles'

interface Props {
  activeLeagueWithBorder: boolean
}

const Leagues = (props: Props) => {
  const dispatch = useDispatch()
  const contentContainerRef = useRef<HTMLDivElement>(null)
  const accountIds = useSelector(getActiveUserAccountIds())
  const activeAccountId = useSelector(getActiveAccountId())
  const [displayAll, setDisplayAll] = useState(false)

  useEffect(() => {
    if (!displayAll) return

    function onResize() {
      if (!contentContainerRef.current) return

      const height = window.innerHeight - contentContainerRef.current.getBoundingClientRect().y
      contentContainerRef.current.style.height = `${height}px`
    }

    const rO = new ResizeObserver(onResize)

    rO.observe(document.body)

    return () => rO.unobserve(document.body)
  }, [displayAll])

  useOnClickOutside(contentContainerRef, () => setDisplayAll(false), displayAll)

  if (!activeAccountId || !accountIds || !accountIds.length) return null

  function onLeagueClick(accountId: string) {
    setDisplayAll(false)
    dispatch(actions.setActiveAccountId(accountId))
  }

  const renderTransition = (children: ReactNode) => (
    <CSSTransition in={displayAll} timeout={300} classNames="transition" unmountOnExit={true}>
      {children}
    </CSSTransition>
  )

  return (
    <>
      <ActiveLeague
        isActive={displayAll}
        accountId={activeAccountId}
        showAll={displayAll}
        onClick={() => setDisplayAll(!displayAll)}
      />
      <RelativeContainer>
        <ContentContainer ref={contentContainerRef}>
          {renderTransition(<Overlay onClick={() => setDisplayAll(false)} />)}
          {renderTransition(
            <Content>
              {accountIds.map((accountId: string) => (
                <League
                  activeWithBorder={props.activeLeagueWithBorder}
                  key={accountId}
                  accountId={accountId}
                  isActive={accountId === activeAccountId}
                  onClick={() => onLeagueClick(accountId)}
                />
              ))}
            </Content>
          )}
        </ContentContainer>
      </RelativeContainer>
    </>
  )
}

export default Leagues

const RelativeContainer = styled.div`
  position: relative;
`

const ContentContainer = styled.div`
  position: absolute;
  width: 100%;
  z-index: 999;
  overflow: auto;
  pointer-events: none;

  > * {
    pointer-events: all;
  }
`

const Content = styled.div`
  max-height: 100%;
  width: 100%;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  transform: translateY(-100%);
  transition: transform 0.3s ease-in-out;

  &.transition-enter-active,
  &.transition-enter-done {
    transform: none;
  }

  &.transition-exit-active {
    transform: translateY(-100%);
  }
`

const Overlay = styled.div`
  ${inset(0)}
  position: absolute;
  background-color: rgba(30, 30, 30, 0.8);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  &.transition-enter-active,
  &.transition-enter-done {
    opacity: 1;
  }

  &.transition-exit-active {
    opacity: 0;
  }
`
